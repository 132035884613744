import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AppIdType,
  ArtifactResourceType,
  BodyRemoveWorkspaceShareResourceV1,
  BodyShareResourceWithWorkspaceV1,
  DeactivateUsersFromWorkspaceRequest,
  EnterpriseOwnedAccount,
  MarkovArtifactMigrationStatus,
  MarkovArtifactMigrationStatusResponse,
  MarkovArtifacts,
  UserOwnedAccount,
  Workspace,
  WorkspaceMembershipRoleUpdateResponse,
  WorkspaceMembershipRoles,
} from '../../generated/api';
import { apiTokensRoutesApi, userManagementApi } from '../../lib/api';
import { appStoreApi } from '../../lib/api/reno';
import { workspaceSetupApi } from '../../lib/api/vienna';
import { documentAIKeys } from '../document-ai/document';
import { SETTINGS, WORKSPACE } from '../queryConstants';

export interface WorkspaceDetails extends Workspace {
  workspaceId: string;
  workspaceName: string;
  ownerAccount: UserOwnedAccount | EnterpriseOwnedAccount;
  memberCount: number;
}

export const useWorkspaceListWithAccountAndMemberDetailsQuery = () => {
  const { userId } = useAppMetadata();

  return useQuery(
    [WORKSPACE.LIST_USER_WORKSPACE_WITH_MEMBER_DETAILS, userId],
    () => userManagementApi.listUserWorkspacesWithMemberDetailsV1(userId),
    {
      staleTime: 0,
      enabled: !!userId,
      select: data => data.data.response,
    },
  );
};

export const useWorkspaceSetupQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<MarkovArtifactMigrationStatusResponse>,
    AxiosError,
    MarkovArtifactMigrationStatus
  >(
    ['workspace-setup-migration-status', workspaceId],
    () => workspaceSetupApi.demoArtifactsMigrationStatusV1(workspaceId),
    {
      enabled: !!workspaceId,
      /**
       * If migration status is true, then don't refetch.
       * If migration status is false, then refetch to get latest status
       **/
      refetchInterval: data => (data === MarkovArtifactMigrationStatus.Loading ? 5 * 1000 : false),
      select: data => data.data.status,
    },
  );
};

export const useGetArtifactCountQuery = (artifactType: MarkovArtifacts) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    ['workspace-artifact-count', workspaceId, artifactType],
    () => workspaceSetupApi.getMarkovArtifactCountV1(workspaceId, artifactType),
    {
      enabled: !!workspaceId,
      staleTime: 0,
      select: response => response.data,
    },
  );
};

export const useGetWorkspaceDetailsQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    ['get-workspace-details', workspaceId],
    () => userManagementApi.getWorkspaceInfoWithMemberDetailsV1(workspaceId),
    {
      enabled: !!workspaceId,
      select: data => data.data,
    },
  );
};

export const useGetWorkspaceSettingsDetailsQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    [SETTINGS.GET_WORKSPACE_SETTINGS, workspaceId],
    () => userManagementApi.getWorkspaceSettingsV1(workspaceId),
    {
      enabled: !!workspaceId,
      select: data => data.data,
    },
  );
};

export const useGetUserInfoQuery = () => {
  const { workspaceId, userId } = useAppMetadata();
  return useQuery(
    ['get-user-info', workspaceId, userId],
    () => userManagementApi.getUserV1(userId),
    {
      select: data => data.data,
      enabled: Boolean(workspaceId && userId),
    },
  );
};

export const useGetWorkspaceMembersQuery = (workspaceIdToFetch?: string) => {
  const { workspaceId } = useAppMetadata();

  const selectedWorkspaceId = workspaceIdToFetch ?? workspaceId;

  return useQuery(
    [WORKSPACE.LIST_WORKSPACE_MEMBERS, selectedWorkspaceId],
    () => userManagementApi.listWorkspaceMembersV1(selectedWorkspaceId),
    {
      staleTime: 0,
      enabled: Boolean(selectedWorkspaceId),
      select: data => data.data,
    },
  );
};

export const useUpdateWorkspaceMembershipMutation = () => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation<
    AxiosResponse<WorkspaceMembershipRoleUpdateResponse>,
    AxiosError,
    { selectedUserId: string; role: WorkspaceMembershipRoles }
  >(
    ({ selectedUserId, role }) =>
      userManagementApi.updateWorkspaceMembershipRoleV1(workspaceId, selectedUserId, role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORKSPACE.LIST_WORKSPACE_MEMBERS, workspaceId]);
      },
    },
  );
};

export const useRemoveMemberFromWorkspaceMutation = () => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (removeMembersReq: DeactivateUsersFromWorkspaceRequest) =>
      userManagementApi.deactivateUserFromWorkspaceV1(workspaceId, removeMembersReq),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORKSPACE.LIST_WORKSPACE_MEMBERS, workspaceId]);
      },
    },
  );
};

export const useGetWorkspaceUsage = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    ['get-workspace-usage', workspaceId],
    () => userManagementApi.getWorkspaceUsageInfoV1(workspaceId),
    {
      select: data => data.data,
      enabled: !!workspaceId,
    },
  );
};

export const useGetUserToken = () => {
  const { workspaceId, userId } = useAppMetadata();

  return useQuery(
    ['get-user-token', workspaceId, userId],
    () =>
      apiTokensRoutesApi.getAPITokenForWorkspaceV1({
        workspaceId: workspaceId,
        userId: userId,
        teamId: '',
      }),
    {
      select: data => data.data,
      enabled: !!workspaceId,
    },
  );
};

export const useWorkspaceListGroupedQuery = () => {
  const { userId } = useAppMetadata();

  return useQuery(
    [WORKSPACE.LIST_USER_WORKSPACE_GROUPED, userId],
    () => userManagementApi.listUserWorkspacesGrouped(userId),
    {
      staleTime: 0,
      enabled: !!userId,
      select: data => data.data,
    },
  );
};

export const useShareResourceWithWorkspaceMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: BodyShareResourceWithWorkspaceV1) =>
      appStoreApi.shareResourceWithWorkspaceV1(workspaceId, req),
    onSuccess: (_, req) => {
      if (req.resource_type === ArtifactResourceType.ProjectCopyEdit) {
        queryClient.invalidateQueries(
          documentAIKeys.appProjectList(workspaceId, AppIdType.CopyEdit),
        );
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, AppIdType.CopyEdit, req.resource_id),
        );
      }
    },
  });
};

export const useUnShareResourceWithWorkspaceMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: BodyRemoveWorkspaceShareResourceV1) =>
      appStoreApi.removeWorkspaceShareResourceV1(workspaceId, req),
    onSuccess: (_, req) => {
      if (req.resource_type === ArtifactResourceType.ProjectCopyEdit) {
        queryClient.invalidateQueries(
          documentAIKeys.appProjectList(workspaceId, AppIdType.CopyEdit),
        );
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, AppIdType.CopyEdit, req.resource_id),
        );
      }
    },
  });
};

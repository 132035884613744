import { IconSquareArrowUpFilled } from '@tabler/icons-react';
import {
  ActionIcon,
  Box,
  Horizontal,
  Text,
  TextArea,
  TextAreaProps,
  Vertical,
  useInputState,
} from '../../../../design-system/v2';

interface ChatPromptInputProps extends Partial<TextAreaProps> {
  onSend: (prompt: string) => void;
  isSendDisabled?: boolean;
}

export const ChatPromptInput = ({
  onSend,
  isSendDisabled = false,
  ...restProps
}: ChatPromptInputProps) => {
  const [currentQuery, setCurrentQuery] = useInputState('');
  const disableSendQuery = isSendDisabled || !currentQuery;

  const handleSend = () => {
    if (disableSendQuery) {
      return;
    }
    onSend(currentQuery);
    setCurrentQuery('');
  };

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.shiftKey && e.code === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  const sendButtonSize = 36;

  return (
    <Vertical spacing={0}>
      <Box w="100%" pos="relative">
        <TextArea
          size="lg"
          ariaLabel="Input text query"
          value={currentQuery}
          onChange={setCurrentQuery}
          onKeyDown={handleInputKeyDown}
          placeholder="Query your data ..."
          autosize
          minRows={3}
          maxRows={15}
          radius="sm"
          styles={{
            input: {
              boxShadow: `
              0px 1px 3px 0px rgba(0, 0, 0, 0.05),
              0px 10px 15px -5px rgba(0, 0, 0, 0.05),
              0px 7px 7px -5px rgba(0, 0, 0, 0.04)
            `,
              paddingRight: sendButtonSize + 10,
            },
          }}
          {...restProps}
        />
        <ActionIcon
          disabled={disableSendQuery}
          color="gray.7"
          onClick={handleSend}
          pos="absolute"
          right={10}
          top={5}
          size={sendButtonSize}
          p={0}
          bg="transparent !important"
          sx={{
            border: 0,
          }}
        >
          <IconSquareArrowUpFilled size="100%" />
        </ActionIcon>
      </Box>
      <Horizontal position="right">
        <Text variant="small03">Press Shift + Return to submit</Text>
      </Horizontal>
    </Vertical>
  );
};

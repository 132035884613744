import { useState } from 'react';
import { DatePickerModal } from '../../DatePickerModal';

interface AddResourceWithStartDateProps {
  onConfirm: (startDate: string) => void;
  isModalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}

export const AddResourceWithStartDate = ({
  onConfirm,
  isModalOpen,
  setModalOpen,
}: AddResourceWithStartDateProps): JSX.Element => {
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const initialDate = new Date();
    initialDate.setFullYear(initialDate.getFullYear() - 1);
    initialDate.setHours(0, 0, 0, 0);
    return initialDate;
  });
  return (
    <DatePickerModal
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      onConfirm={onConfirm}
      title="Select the start date for data fetching"
      initialDate={startDate}
    />
  );
};

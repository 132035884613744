import dayjs from 'dayjs';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  PaymentSubscriptionStatusType,
  PaymentSubscriptionType,
  WorkspaceMembershipRoles,
} from '../../generated/api';
import { useGetWorkspaceMembersQuery } from '../../queries/account/workspace';
import { getCreditLimit } from './plans/constants';

const TRIAL_EXPIRY_THRESHOLD_DAYS = 7;

export const CREDITS_COIN_ICON_URL =
  'https://ik.imagekit.io/markovml/payment-and-subscription/credit-coins_9PvXdjx-3.svg?updatedAt=1730913839922';

export const CREDITS_COIN_ICON_WIDTH = 20;
export const CREDITS_HORIZONTAL_SPACING = 4;

export const PLAN_ICON_WIDTH = 16;
export const PLAN_HORIZONTAL_SPACING = 8;

export const getPlanStatus = (subscriptionStatusType: PaymentSubscriptionStatusType) => {
  switch (subscriptionStatusType) {
    case PaymentSubscriptionStatusType.Trialing:
      return 'Free trial';
    case PaymentSubscriptionStatusType.Active:
      return 'Active';
    case PaymentSubscriptionStatusType.Canceled:
      return 'Canceled';
    default:
      return 'Unknown';
  }
};

/**
 * Calculates the number of days remaining in a trial period.
 * @param trialEnd Unix timestamp representing the trial end date
 * @returns Number of days remaining in trial
 */
export const calculateTrialDaysRemaining = (trialEnd: number): number => {
  const trialEndDate = dayjs.unix(trialEnd);
  const today = dayjs();

  return Math.max(0, trialEndDate.diff(today, 'day'));
};

interface SubscriptionPlanDetails {
  planName: string;
  iconUrl: string;
  description: string;
  features: string[];
}

export const subscriptionPlanDetails: Record<PaymentSubscriptionType, SubscriptionPlanDetails> = {
  [PaymentSubscriptionType.EnterprisePlan]: {
    iconUrl:
      'https://ik.imagekit.io/markovml/payment-and-subscription/crown_HCqXJDGjV.svg?updatedAt=1731063205547',
    planName: 'Enterprise',
    description:
      'For businesses needing advanced data controls and premium support to optimize ROI',
    features: [
      'Dedicated CSM',
      'VPC Deployment',
      'Private LLMs & Apps',
      'Access Control & Audit Logs',
      'Multiple Workspaces',
      'Premium AI Applications',
      'Premium Support',
      'AI Solution Engineer Support',
    ],
  },
  [PaymentSubscriptionType.TeamMonthly599]: {
    iconUrl:
      'https://ik.imagekit.io/markovml/payment-and-subscription/diamond_A52RhbaWA.svg?updatedAt=1730913732966',
    planName: 'Team',
    description: 'For teams to collaborate and build AI workflows and applications on their data',
    features: [
      'Everything in Solo',
      `${getCreditLimit(PaymentSubscriptionType.TeamMonthly599)} Credits/month`,
      '10 Editors',
      'Dedicated Onboarding',
      'Team Collaboration',
      'AI Analytics Studio',
      'Professional Support',
    ],
  },
  [PaymentSubscriptionType.SoloMonthly99]: {
    iconUrl:
      'https://ik.imagekit.io/markovml/payment-and-subscription/stars_2BNIa_kmQ.svg?updatedAt=1731063349638',
    planName: 'Solo',
    description:
      'For solopreneurs to build AI driven workflows & apps to automate data and analysis work',
    features: [
      'Everything in Free',
      `${getCreditLimit(PaymentSubscriptionType.SoloMonthly99)} Credits/month`,
      '1 Editor',
      'Scheduled Workflows',
      'GenAI App Builder',
      'Classical & Gen AI Operators',
      'Email Support',
    ],
  },
  [PaymentSubscriptionType.FreemiumMonthly]: {
    iconUrl:
      'https://ik.imagekit.io/markovml/payment-and-subscription/star_fNxmDuBd0N.svg?updatedAt=1731063496255',
    planName: 'Free',
    description: 'For AI enthusiasts to learn basics & build personal projects',
    features: [
      '1 User',
      `${getCreditLimit(PaymentSubscriptionType.FreemiumMonthly)} Credits/month`,
      'Build AI Applications & Workflows',
      'Limited Data Connectors',
      'Public App Store',
    ],
  },
};

export const isTrialDaysLessThanThreshold = (trialDaysRemaining: number): boolean =>
  trialDaysRemaining < TRIAL_EXPIRY_THRESHOLD_DAYS;

export const isEnterpriseSubscription = (subscriptionType: PaymentSubscriptionType) =>
  subscriptionType === PaymentSubscriptionType.EnterprisePlan;

export const useCanManageSubscription = () => {
  const { isLoading, data, isError } = useGetWorkspaceMembersQuery();
  const { userId } = useAppMetadata();

  const members = data?.workspaceMembers || [];

  const isCurrentUserOwner =
    members.find(member => member.userId === userId)?.workspaceMembershipRole ===
    WorkspaceMembershipRoles.Owner;

  return {
    canManageSubscription: isCurrentUserOwner,
    isLoading: isLoading,
    isError: isError,
  };
};

import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { ResizableMediaNodeView } from './ResizableMediaNodeView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    resizableMedia: {
      /**
       * Set media
       */
      setMedia: (options: {
        'media-type': 'img' | 'video';
        src?: string;
        alt?: string;
        title?: string;
        width?: string;
        height?: string;
        file?: File;
      }) => ReturnType;
    };
  }
}

export interface MediaOptions {
  HTMLAttributes: Record<string, any>;
}

export const ResizableMedia = Node.create<MediaOptions>({
  name: 'resizableMedia',

  inline: false,

  group: 'block',

  draggable: true,

  selectable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      file: {
        default: null,
      },
      src: {
        default: null,
      },
      'media-type': {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      dataAlign: {
        default: 'left', // 'start' | 'center' | 'end'
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]:not([src^="data:"])',
        getAttrs: el => ({
          src: (el as HTMLImageElement).getAttribute('src'),
          'media-type': 'img',
        }),
      },
      {
        tag: 'video',
        getAttrs: el => ({
          src: (el as HTMLVideoElement).getAttribute('src'),
          'media-type': 'video',
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { 'media-type': mediaType } = HTMLAttributes;

    if (mediaType === 'img') {
      return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    }
    if (mediaType === 'video') {
      return [
        'video',
        { controls: 'true', style: 'width: 100%', ...HTMLAttributes },
        ['source', HTMLAttributes],
      ];
    }

    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setMedia:
        options =>
        ({ commands }) => {
          const { 'media-type': mediaType } = options;

          if (mediaType === 'img') {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            });
          }
          if (mediaType === 'video') {
            return commands.insertContent({
              type: this.name,
              attrs: {
                ...options,
                controls: 'true',
              },
            });
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ResizableMediaNodeView);
  },
});

import { ICellRendererParams } from '@ag-grid-community/core';
import { IconEye } from '@tabler/icons-react';
import {
  Button,
  Center,
  Horizontal,
  MODAL_LEVEL_1_Z_INDEX,
  Modal,
  Text,
  useDisclosure,
} from '../../../design-system/v2';
import {
  AirbyteJobStatus,
  DataResourceType,
  ResourceModel,
  StorageType,
} from '../../../generated/api';
import { useGetAirbyteJobStatusQuery } from '../../../queries/connectors';
import { isAirbyteStorageType } from '../../connectors/util';
import { DataSourcePreviewContainer } from '../modals/DataSourcePreview.container';
import { FolderPreview } from '../modals/FolderPreview';
import { ResourceUrlPreview } from '../modals/ResourceUrlPreview';

export const PreviewRenderer = ({
  value: resourceId,
  data: resource,
}: ICellRendererParams<ResourceModel, string>) => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();
  const { data: jobStatusData } = isAirbyteStorageType(resource?.connectorType ?? StorageType.S3)
    ? useGetAirbyteJobStatusQuery(resourceId, { enabled: Boolean(resourceId) })
    : { data: null };

  const isPreviewDisabled = isAirbyteStorageType(resource?.connectorType ?? StorageType.S3)
    ? jobStatusData?.jobStatus !== AirbyteJobStatus.Succeeded
    : false;
  const isFolderResource = resource?.resourceType === DataResourceType.Folder;

  if (isFolderResource) {
    return (
      <>
        <Horizontal h="100%" align="center">
          <Button leftIcon={<IconEye />} onClick={openModal}>
            View files
          </Button>
        </Horizontal>
        <Modal
          centered
          opened={modalOpened}
          onClose={closeModal}
          size="90vw"
          title={<Text variant="subTitle01">Resource details</Text>}
          zIndex={MODAL_LEVEL_1_Z_INDEX}
        >
          <FolderPreview resourceId={resourceId} />
        </Modal>
      </>
    );
  }

  const isDataSink = resource?.isDataSink ?? true;

  if (isDataSink) {
    return (
      <Center h="100%">
        <Button
          leftIcon={<IconEye />}
          disabled
          // Removing the border to have same look and feel as the enabled state
          sx={{
            ':disabled': {
              background: 'transparent',
              border: 'none',
            },
          }}
        >
          Preview
        </Button>
      </Center>
    );
  }

  const isPDFResource = resource?.resourceType === DataResourceType.Pdf;

  return (
    <>
      <Modal
        centered
        opened={modalOpened}
        onClose={closeModal}
        size="90vw"
        title={<Text variant="subTitle01">Resource details</Text>}
        zIndex={MODAL_LEVEL_1_Z_INDEX}
      >
        {isPDFResource ? (
          <ResourceUrlPreview resourceId={resourceId} />
        ) : (
          <DataSourcePreviewContainer resourceId={resourceId} height="60vh" />
        )}
      </Modal>
      <Horizontal h="100%" align="center">
        <Button leftIcon={<IconEye />} onClick={openModal} disabled={isPreviewDisabled}>
          Preview
        </Button>
      </Horizontal>
    </>
  );
};

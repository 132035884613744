import isEmpty from 'lodash/isEmpty';
import { useTableContext } from '../../../../../contexts/table/TableContext';
import { Filter } from '../../../../Icons';
import {
  ActionIcon,
  Button,
  Horizontal,
  Popover,
  TextInput,
  Vertical,
  useDisclosure,
  useInputState,
  useMarkovTheme,
} from '../../../../v2';

const TEXT_COLUMN_FILTER_WIDTH = 220;

interface TextColumnFilterDropdownProps {
  defaultValue: string | undefined;
  onSave: (value: string | undefined) => void;
}

const TextColumnFilterDropdown = ({ defaultValue, onSave }: TextColumnFilterDropdownProps) => {
  const [filterValue, setFilterValue] = useInputState(defaultValue);

  const onApplyFilter = () => {
    onSave(filterValue);
  };

  return (
    <Vertical spacing="sm">
      <TextInput
        autoFocus
        ariaLabel="Filter records..."
        placeholder="Filter records..."
        value={filterValue ?? ''}
        onChange={setFilterValue}
      />
      <Horizontal position="right">
        <Button variant="primary" onClick={onApplyFilter}>
          Apply
        </Button>
      </Horizontal>
    </Vertical>
  );
};

interface TextColumnFilterProps {
  columnId: string;
}

export const TextColumnFilter = ({ columnId }: TextColumnFilterProps): JSX.Element => {
  const theme = useMarkovTheme();
  const { filters, setFilters } = useTableContext();
  const [opened, { close: closeMenu, toggle }] = useDisclosure(false);

  const handleSave = (filterValue: string | undefined) => {
    const nextFilters = { ...filters };
    if (!isEmpty(filterValue)) {
      nextFilters[columnId] = filterValue;
    } else if (nextFilters[columnId]) {
      delete nextFilters[columnId];
    }
    setFilters(nextFilters);
    closeMenu();
  };

  const handleClose = () => {
    closeMenu();
  };

  const filterValue = filters[columnId] as string | undefined;
  const iconColor = filterValue ? theme.colors.blue[8] : theme.colors.gray[5];

  return (
    <Popover
      trapFocus
      opened={opened}
      onChange={toggle}
      onClose={handleClose}
      position="bottom-end"
      shadow="lg"
      width={TEXT_COLUMN_FILTER_WIDTH}
    >
      <Popover.Target>
        <ActionIcon size="xs" onClick={toggle}>
          <Filter fill={iconColor} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <TextColumnFilterDropdown onSave={handleSave} defaultValue={filterValue} />
      </Popover.Dropdown>
    </Popover>
  );
};

import keyBy from 'lodash/keyBy';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { COMPARISON_COLORS } from '../../charts/config/colors';
import { EvaluationComparisonErrorScreen } from '../../components/evaluation-comparison/common/ErrorScreen';
import { EvaluationFullDetails } from '../../generated/api';
import { useEvaluationsFullDetailsQueries } from '../../queries/evaluations/evaluationComparison';

interface BasicEvaluationDetails {
  evaluationId: string;
  name: string;
}

interface EvaluationComparisonState {
  evaluationIds: string[];
  isLoading: boolean;
  isError: boolean;
  evaluationsSummary: EvaluationFullDetails[];
  evaluationDetailsById: Record<string, BasicEvaluationDetails>;
}

const defaultValue = {
  evaluationIds: [],
  isLoading: false,
  isError: false,
  evaluationsSummary: [],
  evaluationDetailsById: {},
};

const EvaluationComparisonContext = createContext<EvaluationComparisonState>(defaultValue);

export const useEvaluationComparisonContext = () => useContext(EvaluationComparisonContext);

export const useEvaluationColor = () => {
  const { evaluationIds } = useEvaluationComparisonContext();

  return {
    getColor: (evaluationId: string) => {
      const evalIndex = evaluationIds.indexOf(evaluationId);
      if (evalIndex === -1) {
        throw 'Evaluation is not part of current comparison';
      }
      if (evalIndex > COMPARISON_COLORS.length) {
        throw 'No color defined for evaluation position';
      }
      const color = COMPARISON_COLORS[evalIndex];
      return color;
    },
  };
};

interface EvaluationComparisonProviderProps {
  evaluationIds: string[];
}

export const EvaluationComparisonProvider = ({
  evaluationIds,
  children,
}: PropsWithChildren<EvaluationComparisonProviderProps>) => {
  const queries = useEvaluationsFullDetailsQueries(evaluationIds);

  const isLoading = queries.some(query => query.isLoading);
  const isError = queries.some(query => query.isError);

  const value = useMemo(() => {
    const evaluationsSummary = queries.map(query => query.data as EvaluationFullDetails);
    const isDataReady = evaluationsSummary.every(data => data);
    const evaluationDetailsById = isDataReady
      ? keyBy(
          evaluationsSummary.map(({ evaluation }) => ({
            evaluationId: evaluation.recordingId,
            name: evaluation.name ?? '',
          })),
          'evaluationId',
        )
      : {};
    return {
      isLoading,
      isError,
      evaluationIds,
      evaluationsSummary,
      evaluationDetailsById,
    };
  }, [queries, isLoading, isError, evaluationIds]);

  // All page content relies on this information. If there is an error fetching
  // data at this level, no other page sections can show meaningful content.
  if (isError) {
    return <EvaluationComparisonErrorScreen />;
  }

  return (
    <EvaluationComparisonContext.Provider value={value}>
      {children}
    </EvaluationComparisonContext.Provider>
  );
};

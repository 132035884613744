import { LayoutProps, RankedTester, VerticalLayout, rankWith, uiTypeIs } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { IconInfoCircle } from '@tabler/icons-react';
import { useState } from 'react';
import {
  Center,
  Horizontal,
  Image,
  Text,
  UnstyledButton,
  Vertical,
  useMarkovTheme,
} from '../../../../../design-system/v2';

export const SectionWithHelpLayoutComponent = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
  direction,
  label,
}: LayoutProps) => {
  const theme = useMarkovTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  if (!visible) {
    return null;
  }

  const verticalLayout = uischema as VerticalLayout;
  const uiSchemaOptions = uischema.options;
  const buttonText = uiSchemaOptions?.buttonText ?? '';
  const expandItems = uiSchemaOptions?.expandItems ?? [];

  const handleButtonClick = () => setIsExpanded(prev => !prev);

  return (
    <Vertical spacing={uiSchemaOptions?.spacing ?? 24}>
      {verticalLayout.elements.map((element, idx) => (
        <JsonFormsDispatch
          key={`${path}_${idx}`}
          uischema={element}
          schema={schema}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
        />
      ))}
      <UnstyledButton onClick={handleButtonClick}>
        <Horizontal noWrap spacing="sm">
          <IconInfoCircle size={24} color={theme.colors.gray[6]} />
          <Text variant="textLink" color="gray.6">
            {buttonText}
          </Text>
        </Horizontal>
      </UnstyledButton>
      {isExpanded && (
        <Vertical spacing="xxl">
          {expandItems.map((item: { title: string; illustrationUrl: string }, idx: number) => (
            <Vertical key={item.title}>
              <Horizontal noWrap>
                <Center h={32} w={32} bg="dark.2" sx={{ borderRadius: '50%' }}>
                  <Text variant="bodyShort01" color="white.0">
                    {idx + 1}
                  </Text>
                </Center>
                <Text variant="bodyShort02" color="gray.8">
                  {item.title}
                </Text>
              </Horizontal>
              <Image src={item.illustrationUrl} withPlaceholder />
            </Vertical>
          ))}
        </Vertical>
      )}
    </Vertical>
  );
};

export const SectionWithHelpLayoutControl = withJsonFormsLayoutProps(
  SectionWithHelpLayoutComponent,
);

export const sectionWithHelpControlTester: RankedTester = rankWith(
  1,
  uiTypeIs('SectionWithHelpLayout'),
);

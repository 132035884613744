import { Checkbox } from '@mantine/core';
import { clsx } from '@mantine/styles';
import noop from 'lodash/noop';
import React, { forwardRef } from 'react';
import Box from '../../../../../../../Box';
import { Horizontal } from '../../../../../layout';
import { Text } from '../../../../../typography';

export interface SelectItemProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'value'> {
  label: React.ReactNode;
  value?: string;
  selected?: boolean;
}

export const DefaultItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, selected, ...others }: SelectItemProps, ref) => (
    <Box ref={ref} {...others} className={clsx(others.className)}>
      <Horizontal>
        <Checkbox
          checked={selected}
          onChange={noop}
          sx={{ display: 'flex', alignSelf: 'center' }}
        />
        <Text
          span
          lineClamp={1}
          sx={{ wordBreak: 'break-word', maxWidth: '150px' }}
          title={typeof label === 'string' ? label : undefined}
        >
          {label || value}
        </Text>
      </Horizontal>
    </Box>
  ),
);

DefaultItem.displayName = 'MKVDefaultItem';

import { PaymentSubscriptionType } from '../../../generated/api';

const MONTHLY_CREDIT_LIMIT_FREE_PLAN = 1_000;
const MONTHLY_CREDIT_LIMIT_SOLO_PLAN = 16_000;
const MONTHLY_CREDIT_LIMIT_TEAM_PLAN = 120_000;

const MONTHLY_COST_FREE_PLAN_USD = 0;

const MONTHLY_COST_SOLO_PLAN_BILLED_MONTHLY_USD = 99;
const MONTHLY_COST_SOLO_PLAN_BILLED_ANNUALLY_USD = 79;

const MONTHLY_COST_TEAM_PLAN_BILLED_MONTHLY_USD = 599;
const MONTHLY_COST_TEAM_PLAN_BILLED_ANNUALLY_USD = 479;

export type BillingFrequency = 'monthly' | 'annually';

type NonEnterpriseSubscriptionType =
  | PaymentSubscriptionType.FreemiumMonthly
  | PaymentSubscriptionType.SoloMonthly99
  | PaymentSubscriptionType.TeamMonthly599;

const MONTHLY_CREDIT_LIMITS: Record<NonEnterpriseSubscriptionType, number> = {
  [PaymentSubscriptionType.FreemiumMonthly]: MONTHLY_CREDIT_LIMIT_FREE_PLAN,
  [PaymentSubscriptionType.SoloMonthly99]: MONTHLY_CREDIT_LIMIT_SOLO_PLAN,
  [PaymentSubscriptionType.TeamMonthly599]: MONTHLY_CREDIT_LIMIT_TEAM_PLAN,
};

export const getCreditLimit = (subscriptionType: NonEnterpriseSubscriptionType): number =>
  MONTHLY_CREDIT_LIMITS[subscriptionType];

const MONTHLY_COST_USD: Record<
  NonEnterpriseSubscriptionType,
  { [key in BillingFrequency]: number }
> = {
  [PaymentSubscriptionType.FreemiumMonthly]: {
    monthly: MONTHLY_COST_FREE_PLAN_USD,
    annually: MONTHLY_COST_FREE_PLAN_USD,
  },
  [PaymentSubscriptionType.SoloMonthly99]: {
    monthly: MONTHLY_COST_SOLO_PLAN_BILLED_MONTHLY_USD,
    annually: MONTHLY_COST_SOLO_PLAN_BILLED_ANNUALLY_USD,
  },

  [PaymentSubscriptionType.TeamMonthly599]: {
    monthly: MONTHLY_COST_TEAM_PLAN_BILLED_MONTHLY_USD,
    annually: MONTHLY_COST_TEAM_PLAN_BILLED_ANNUALLY_USD,
  },
};

export const getMonthlyCostUsd = (
  subscriptionType: NonEnterpriseSubscriptionType,
  frequency: BillingFrequency,
) => MONTHLY_COST_USD[subscriptionType][frequency];

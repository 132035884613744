import noop from 'lodash/noop';
import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { TagMetadata } from '../../generated/api';

interface TableFiltersState {
  selectedTags: TagMetadata[];
  setSelectedTags: (selectedTags: TagMetadata[]) => void;
}

const initialState: TableFiltersState = {
  selectedTags: [],
  setSelectedTags: noop,
};

const TableFiltersContext = createContext<TableFiltersState>(initialState);

export const useTableFiltersContext = () => useContext(TableFiltersContext);

// TODO: Delete this context/module
export const TableFiltersContextProvider = ({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element => {
  const [selectedTags, setSelectedTags] = useState(initialState.selectedTags);
  const selectedTagIds = selectedTags.map(t => t.tagId);

  const value: TableFiltersState = useMemo(
    () => ({
      selectedTags,
      setSelectedTags,
    }),
    [selectedTagIds.join(',')],
  );

  return <TableFiltersContext.Provider value={value}>{children}</TableFiltersContext.Provider>;
};

export const TableFilterConsumer = ({
  children,
}: {
  children: (p: TableFiltersState) => ReactElement;
}) => <TableFiltersContext.Consumer>{children}</TableFiltersContext.Consumer>;

import { IconRefresh } from '@tabler/icons-react';
import { ReactNode, useEffect } from 'react';
import { ActionIcon, Box, Horizontal, ScrollArea } from '../../../../design-system/v2';
import { StorageType } from '../../../../generated/api';
import {
  useGetConnectorDetailsQuery,
  useRefetchConnectorResourcesList,
} from '../../../../queries/connectors';
import { EmptyState } from '../../../common/EmptyState';
import { ResourceActionsParams } from '../../connector-details/actions/ResourceActions';
import { ConnectorBreadcrumbs } from '../../connector-resources/ConnectorBreadcrumbs';
import { ConnectorResourcesContainer } from '../../connector-resources/ConnectorResources.container';
import { useResourceExplorer } from '../../connector-resources/useResourceExplorer';

interface ConnectorResourceExplorerProps {
  connectorId: string | null;
  onSelectResource?: (resourceId: string) => void;
  getResourceActions?: (params: ResourceActionsParams) => ReactNode;
  leafSelectable?: boolean;
}

export const ConnectorResourceExplorer = ({
  connectorId,
  onSelectResource,
  getResourceActions,
  leafSelectable,
}: ConnectorResourceExplorerProps): JSX.Element => {
  const { currentPath, pushResource, popResource, setDepth } = useResourceExplorer();
  const connectorQuery = useGetConnectorDetailsQuery(connectorId ?? '');
  const refetchConnectorResources = useRefetchConnectorResourcesList(
    connectorId ?? '',
    currentPath,
  );

  // Reset resource path when connector changes
  useEffect(() => {
    setDepth(0);
  }, [connectorId]);

  if (!connectorId) {
    return <EmptyState title="Select or add a connector to continue" />;
  }

  const handleRefresh = () => {
    refetchConnectorResources();
  };

  const connectorType = connectorQuery.data?.connectorType ?? StorageType.S3;
  return (
    <Box h="100%">
      <Horizontal position="apart">
        <ConnectorBreadcrumbs
          connectorType={connectorType}
          resourcesHierarchy={currentPath}
          onChangeLevel={setDepth}
        />
        <ActionIcon onClick={handleRefresh}>
          <IconRefresh />
        </ActionIcon>
      </Horizontal>
      <ScrollArea h={'calc(100% - 72px)'}>
        <ConnectorResourcesContainer
          connectorId={connectorId}
          connectorType={connectorType}
          resourcesHierarchy={currentPath}
          onBacktrack={popResource}
          onExploreResource={pushResource}
          getResourceActions={getResourceActions}
          onSaveResource={onSelectResource}
          leafSelectable={leafSelectable}
        />
      </ScrollArea>
    </Box>
  );
};

import { ampli } from '../../../../ampli';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { GetWorkspaceCurrentSubscriptionDetailResponseModel } from '../../../../generated/api';
import { useCreateBillingPortalSessionMutation } from '../../../../queries/account/payment-and-subscription';
import { PlanSection } from './PlanSection';

interface PlanSectionContainerProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
  canManageSubscription: boolean;
}

export const PlanSectionContainer = ({
  subscriptionDetail,
  canManageSubscription,
}: PlanSectionContainerProps) => {
  const { workspaceId } = useAppMetadata();

  const returnUrl = window.location.href;
  const {
    isLoading: isBillingPortalSessionLoading,
    isError: isBillingPortalSessionError,
    mutateAsync: createBillingPortalSession,
  } = useCreateBillingPortalSessionMutation(returnUrl);

  const handleManageSubscription = async () => {
    ampli.billingAndSubscriptionManageSubscriptionClicked({ workspaceId });
    const res = await createBillingPortalSession();
    window.open(res.data.portalUrl);
  };

  return (
    <PlanSection
      subscriptionDetail={subscriptionDetail}
      isBillingPortalSessionLoading={isBillingPortalSessionLoading}
      isBillingPortalSessionError={isBillingPortalSessionError}
      canManageSubscription={canManageSubscription}
      handleManageSubscription={handleManageSubscription}
    />
  );
};

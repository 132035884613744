import { useState } from 'react';
import { Alert, Button, Horizontal, closeAllModals } from '../../../design-system/v2';
import { ResourceType, TagMetadata } from '../../../generated/api';
import { useUpdateTagsOfResourceMutation } from '../../../queries/tagging';
import { TagSelectContainer } from '../../tag-select/TagSelect.container';

interface AddEditTagsModalContentProps {
  resourceId: string;
  resourceType: ResourceType;
  tags?: TagMetadata[];
  onSuccess: () => Promise<unknown> | void;
}

export const AddEditTagsModalContent = ({
  resourceId,
  resourceType,
  tags,
  onSuccess,
}: AddEditTagsModalContentProps) => {
  const {
    mutate: updateTags,
    isLoading,
    isError,
  } = useUpdateTagsOfResourceMutation(resourceId, resourceType, async () => {
    await onSuccess();
    closeAllModals();
  });

  const currentTagIds = tags?.map(tag => tag.tagId) ?? [];
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>(currentTagIds);

  const handleSave = () => {
    updateTags({ tagIds: selectedTagIds });
  };

  const handleCancel = () => {
    closeAllModals();
  };

  return (
    <>
      {isError && <Alert color="red">Error saving changes, please retry or contact suport</Alert>}
      <TagSelectContainer selectedTagIds={selectedTagIds} setSelectedTagIds={setSelectedTagIds} />
      <Horizontal mt="xl" position="right">
        <Button variant="secondary" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} loading={isLoading}>
          {isLoading ? 'Saving' : 'Apply changes'}
        </Button>
      </Horizontal>
    </>
  );
};

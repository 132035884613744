import { Text as MantineText, TextProps as MantineTextProps, TitleProps } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/utils';
import { forwardRef } from 'react';
import { HeadingVariants } from '.';
import { useTextStyles } from './Text.style';
import { TextVariants, isHeading, isTextLink } from './constants';
import { Heading } from './headings/Heading';

type PolymorphicTextProps<C = 'div'> = PolymorphicComponentProps<C, MantineTextProps & TitleProps>;

export interface TextProps extends Omit<PolymorphicTextProps, 'variant' | 'gradient'> {
  variant?: TextVariants | HeadingVariants;
}

export const Text = forwardRef<HTMLDivElement, TextProps>(
  ({ variant = 'bodyShort02', children, className, ...rest }, ref) => {
    const { classes, cx } = useTextStyles({ variant: variant as TextVariants });

    /** Mantine treats Text and Heading components very differently.  */
    if (isHeading(variant)) {
      return (
        <Heading variant={variant} {...rest}>
          {children}
        </Heading>
      );
    }

    const isVariantLink = isTextLink(variant);

    return (
      <MantineText
        ref={ref}
        variant={isVariantLink ? 'link' : undefined}
        className={cx(classes.root, className)}
        td={isVariantLink ? 'underline' : ''}
        {...rest}
      >
        {children}
      </MantineText>
    );
  },
);

Text.displayName = 'Text';

import { RichTextEditor, RichTextEditorProps } from '@mantine/tiptap';
import noop from 'lodash/noop';
import { SelectionBubbleMenu } from './menus/SelectionBubbleMenu';

interface TextEditorProps extends Omit<RichTextEditorProps, 'children'> {
  useBubbleMenu?: boolean;
  onClickCommentControl?: (conversationId: string) => void;
  onClickAudioControl?: (text: string) => void;
  onClickAudioStopControl?: () => void;
  isLoadingAudio?: boolean;
  isPlayingAudio?: boolean;
  onClickTranslateControl?: (text: string) => void;
  showHeadingControls?: boolean;
  showCommentControls?: boolean;
  showLinkControls?: boolean;
  showFormattingControls?: boolean;
  showAudioControls?: boolean;
  showTranslateControls?: boolean;
}

export const TextEditor = ({
  editor,
  useBubbleMenu,
  onClickCommentControl = noop,
  onClickAudioControl = noop,
  onClickAudioStopControl = noop,
  isLoadingAudio = false,
  isPlayingAudio = false,
  onClickTranslateControl = noop,
  showHeadingControls = true,
  showCommentControls = true,
  showLinkControls = true,
  showFormattingControls = true,
  showAudioControls = false,
  showTranslateControls = false,
  ...props
}: TextEditorProps) => (
  <div role="application" aria-roledescription="rich text editor">
    <RichTextEditor editor={editor} {...props} sx={{ zIndex: 10 }}>
      {editor && useBubbleMenu && (
        <SelectionBubbleMenu
          onClickCommentControl={onClickCommentControl}
          onClickAudioControl={onClickAudioControl}
          onClickAudioStopControl={onClickAudioStopControl}
          isLoadingAudio={isLoadingAudio}
          isPlayingAudio={isPlayingAudio}
          onClickTranslateControl={onClickTranslateControl}
          showHeadingControls={showHeadingControls}
          showCommentControls={showCommentControls}
          showLinkControls={showLinkControls}
          showFormattingControls={showFormattingControls}
          showAudioControls={showAudioControls}
          showTranslateControls={showTranslateControls}
        />
      )}
      <RichTextEditor.Content />
    </RichTextEditor>
  </div>
);

TextEditor.displayName = 'TextEditor';

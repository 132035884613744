import { IconBrandAzure, IconFileText } from '@tabler/icons-react';
import React from 'react';
import {
  AmazonS3,
  GoogleDrive,
  GoogleSheet,
  IconPostgres,
  SnowflakeLogo,
} from '../../design-system';
import { Image } from '../../design-system/v2';
import { AirbyteStorageType, StorageType } from '../../generated/api';

export const AirbyteStorageIcons: Record<AirbyteStorageType, React.FC<{ width: number }>> = {
  [AirbyteStorageType.Hubspot]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-hubspot_sv9IAQzvOj.svg"
      width={width}
    />
  ),
  [AirbyteStorageType.Airtable]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-airtable_VOjGCXFKDd.svg"
      width={width}
    />
  ),
  [AirbyteStorageType.GoogleAnalytics]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-google-analytics_XEqS6ow3j6.svg"
      width={width}
    />
  ),
  [AirbyteStorageType.LinkedinAds]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-linkedin_Qh86M_So26.svg"
      width={width}
    />
  ),
  [AirbyteStorageType.Salesforce]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-salesforce_8a8FZCHIsj.svg"
      width={width}
    />
  ),
  [AirbyteStorageType.Zendesk]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-zendesk_2LK7MuzEs.svg"
      width={width}
    />
  ),
  [AirbyteStorageType.GoogleAds]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-google-ads_58BzvJRkb5.svg"
      width={width}
    />
  ),
  [AirbyteStorageType.Gong]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-gong_NR71lUgMvA.svg"
      width={width}
    />
  ),
};

export const isAirbyteStorageType = (connectorType: StorageType): boolean =>
  Object.values(AirbyteStorageType)
    .map(v => v.toString())
    .includes(connectorType);

export const getAllAirbyteStorageTypes = (): AirbyteStorageType[] =>
  Object.values(AirbyteStorageType);

export const getConnectorTypeDisplayName = (connectorType: StorageType): string =>
  connectorType
    .replace(/[_-]/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const getConnectorTypeIcon = (connectorType: StorageType, width = 24): JSX.Element => {
  switch (connectorType) {
    case StorageType.S3:
      return <AmazonS3 width={width} fill="transparent" />;
    case StorageType.Snowflake:
      return <SnowflakeLogo width={width} />;
    case StorageType.Postgresql:
      return <IconPostgres width={width} />;
    case StorageType.AzureBlob:
      return <IconBrandAzure size={width} />;
    case StorageType.MarkovLibrary:
      return <IconFileText width={width} />;
    case StorageType.GoogleSheet:
      return <GoogleSheet height={width} />;
    case StorageType.GoogleDrive:
      return <GoogleDrive width={width} />;
    default:
      if (isAirbyteStorageType(connectorType)) {
        const AirbyteStorageIcon = (AirbyteStorageIcons as any)[connectorType.toString()];

        if (AirbyteStorageIcon) {
          return <AirbyteStorageIcon width={width} />;
        }
      }
      return <></>;
  }
};

import { ControlProps, RankedTester, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box } from '../../../../../design-system/v2';
import {
  AsyncDropDownResources,
  BaseAPIFilter,
  ResourceKey,
  StorageType,
} from '../../../../../generated/api';
import { useInvalidateDataSourcesList } from '../../../../../queries/data-sources';
import { DataSinkResourceActions } from '../../../../connectors/connector-details/actions/DataSinkResourceActions';
import { ResourceActionsParams } from '../../../../connectors/connector-details/actions/ResourceActions';
import { ResourcePreviewButton } from '../../../../connectors/connector-details/actions/ResourcePreviewButton';
import { isFileSupported } from '../../../../connectors/connector-resources/util';
import { ConnectorSourceOperatorTableView } from '../../../../workflows/create/workflow-builder/operator-parameter-form/connector-source/ConnectorSourceOperatorTableView';
import { getDataSourceActions } from '../../../resource-lookup/AddResourceModal';
import { ResourceSummary } from '../../../resource-lookup/ResourceSummary';

const getS3FolderActions = ({
  connectorId,
  resource,
  resourcesHierarchy,
  onResourceAdd,
}: ResourceActionsParams): JSX.Element | null => {
  const { key, value, isAdded = false } = resource;

  const resourcePath = [...resourcesHierarchy, { key, value }];
  if (key === ResourceKey.S3Folder) {
    return (
      <DataSinkResourceActions
        connectorId={connectorId}
        connectorType={StorageType.S3}
        resourcePath={resourcePath}
        isAdded={isAdded}
        onResourceAdd={onResourceAdd}
      />
    );
  }

  if (key === ResourceKey.S3File) {
    const isUnsupportedFileType = !isFileSupported(value);

    if (isUnsupportedFileType) {
      return null;
    }

    return <ResourcePreviewButton connectorId={connectorId} resourcePath={resourcePath} />;
  }

  return null;
};

const ResourceLookupWithListComponent = ({
  label,
  description,
  handleChange,
  path,
  uischema,
  visible,
  enabled,
  data,
  config,
  errors,
}: ControlProps): JSX.Element | null => {
  const invalidate = useInvalidateDataSourcesList();
  if (!visible) {
    return null;
  }

  const onChange = (value: string) => {
    handleChange(path, value);
    // Invalidation needed to show the name of the newly added resource in ResourceSummary
    invalidate();
  };

  const resourceLookupOptions = uischema.options?.resourceLookupOptions;
  const filters = resourceLookupOptions?.filters as BaseAPIFilter[] | undefined;
  const connectorType = filters?.find(filter => filter.field === 'connectorType')?.value as
    | StorageType
    | undefined;
  const resourceKey = resourceLookupOptions?.resourceKey as AsyncDropDownResources | undefined;
  const isDataResource = resourceKey === AsyncDropDownResources.DataResources;
  const isDataSink = resourceKey === AsyncDropDownResources.DataSinks;

  const isEditable = !config.viewOnly && enabled;
  const showTableView = isEditable && connectorType && !data;

  // Temp fix for critical bug with PDF folder source operator
  let getResourceActions = undefined;

  if (connectorType === StorageType.S3) {
    getResourceActions = isDataSink
      ? getS3FolderActions
      : isDataResource
      ? getDataSourceActions
      : undefined;
  }

  return (
    <>
      {showTableView && (
        // Fill form modal with table when user is selecting a data source
        <Box pos="absolute" top={0} left={0} right={0} bottom={0} bg="white.0" sx={{ zIndex: 10 }}>
          <ConnectorSourceOperatorTableView
            connectorType={connectorType}
            onSelectResource={onChange}
            allowAddResource={resourceLookupOptions.allowAddResource}
            isDataSink={isDataSink}
            getResourceActions={getResourceActions}
            leafSelectable={connectorType === StorageType.S3 && !isDataSink}
          />
        </Box>
      )}
      <ResourceSummary
        value={data}
        description={description ?? ''}
        isDataSink={isDataSink}
        label={label ?? ''}
        onChange={onChange}
        isEditable={isEditable}
      />
    </>
  );
};

export const resourceLookupWithListControlTester: RankedTester = rankWith(
  7,
  uiSchema =>
    uiSchema?.options?.resourceLookupOptions?.resourceKey ===
      AsyncDropDownResources.DataResources ||
    uiSchema?.options?.resourceLookupOptions?.resourceKey === AsyncDropDownResources.DataSinks,
);

export const ResourceLookupWithListControl = withJsonFormsControlProps(
  ResourceLookupWithListComponent,
);

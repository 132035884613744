import {
  SegmentedControl as MantineSegmentedControl,
  SegmentedControlProps as MantineSegmentedControlProps,
} from '@mantine/core';

type SegmentedControlProps = MantineSegmentedControlProps & React.RefAttributes<HTMLDivElement>;

export const SegmentedControl = (props: SegmentedControlProps) => (
  <MantineSegmentedControl color="blue.9" radius="xs" {...props} />
);

import {
  ControlProps,
  RankedTester,
  and,
  hasType,
  optionIs,
  rankWith,
  schemaMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useState } from 'react';
import { FileWithPath, Text, Vertical, notifications } from '../../../../../design-system/v2';
import { MEMORY_UNIT_LIMITS } from '../../../../../lib/resource-usage';
import { FileInputSelector } from '../../../uploader/file-uploader/FileInputSelector';

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const DropzoneControlComponent = ({
  label,
  description,
  schema,
  handleChange,
  path,
  required,
  uischema,
  data,
  visible,
  enabled,
  errors,
  config,
}: ControlProps) => {
  const [inputFile, setInputFile] = useState<FileWithPath>();

  const uiSchemaOptions = uischema.options;
  const allowedFileSize = uiSchemaOptions?.allowedFileSize;
  const supportedFileTypes = uiSchemaOptions?.supportedFileTypes as string[];

  if (!visible) {
    return null;
  }

  const onChange = (content: string, fileName: string) => {
    handleChange(path, { content, fileName });
  };

  const readFileContent = (file: File) => {
    const reader = new FileReader();

    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      reader.onload = event => {
        if (event.target?.result) {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const base64String = arrayBufferToBase64(arrayBuffer);
          onChange(base64String, file.name);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      reader.onload = event => {
        if (event.target?.result) {
          onChange(event.target.result as string, file.name);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDropFile = (file: FileWithPath) => {
    const fileSize = Number(((file.size ?? allowedFileSize) / MEMORY_UNIT_LIMITS.MB).toFixed(4));

    if (fileSize > allowedFileSize) {
      notifications.error(`The file size exceeds ${allowedFileSize}MB.`);
      return;
    }

    setInputFile(file);
    readFileContent(file);
  };

  const handleFileDelete = () => {
    setInputFile(undefined);
    onChange('', '');
  };

  return (
    <Vertical spacing={0}>
      <Text span variant="subTitle02">
        {label}
      </Text>
      <Text span variant="small02" color="gray.7" mb="sm">
        {description}
      </Text>
      <FileInputSelector
        file={inputFile}
        supportedFilesTypes={supportedFileTypes}
        onFileSelect={handleDropFile}
        onFileDelete={handleFileDelete}
      />
    </Vertical>
  );
};

export const dropzoneControlTester: RankedTester = rankWith(
  3,
  and(
    uiTypeIs('Control'),
    optionIs('dropzone', true),
    schemaMatches(schema => hasType(schema, 'object')),
  ),
);

export const DropzoneControl = withJsonFormsControlProps(DropzoneControlComponent);

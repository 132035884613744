import { JsonFormsCore, createAjv, generateDefaultUISchema } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import type Ajv from 'ajv';
import { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Horizontal,
  Loader,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
} from '../../../design-system/v2';
import { StorageType } from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import {
  useCreateConnectorMutation,
  useGetAirbyteConnectorJsonQuery,
} from '../../../queries/connectors';
import { renderers } from '../../common/form/renderers';

interface AirbyteConnectorModalProps {
  connectorType: StorageType;
}

export const AirbyteConnectorModal = ({ connectorType }: AirbyteConnectorModalProps) => {
  const [connectorName, setConnectorName] = useState('');
  const [nameError, setNameError] = useState<string | undefined>();
  const [values, setValues] = useState<any>({});
  const {
    isLoading,
    isError,
    data: connectorConfig,
  } = useGetAirbyteConnectorJsonQuery(connectorType);
  const { mutateAsync: createConnector, isLoading: creatingConnector } =
    useCreateConnectorMutation();

  if (isLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box>
        <Alert>Error fetching configuration for connector</Alert>
      </Box>
    );
  }

  const handleConnectorNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value) {
      setNameError(undefined);
    }

    setConnectorName(value);
  };

  const handleFormChange = ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    setValues(data);
  };

  const handleSubmit = async () => {
    if (!connectorName) {
      setNameError('Connector name is mandatory');
      return;
    }

    try {
      await createConnector({
        connectorType,
        credData: values as any,
        name: connectorName,
        connectorMetadata: {},
      });
    } catch (e) {
      logger.error('Create connection failed');
      notifications.error('Failed to create connection');
      return;
    }

    notifications.success('Connection created');
    closeAllModals();
  };

  const handleDefaultsAjv = createAjv({ useDefaults: true }) as unknown as Ajv;
  const parameters = (connectorConfig as any)?.parameters;

  return (
    <Vertical h="100%" spacing={24} justify="space-between">
      <TextInput
        required
        ariaLabel="Connector name"
        value={connectorName}
        onChange={handleConnectorNameChange}
        placeholder="Type to give a name to the connector"
        error={nameError}
        label={
          <Text span variant="subTitle02">
            Connector name
          </Text>
        }
      />
      <JsonForms
        schema={parameters.schema}
        uischema={generateDefaultUISchema(parameters.schema)}
        data={values}
        renderers={renderers}
        onChange={handleFormChange}
        readonly={creatingConnector}
        validationMode="ValidateAndShow"
        ajv={handleDefaultsAjv}
      />
      <Horizontal pt="lg">
        <Button
          w="168px"
          ml="auto"
          variant="primary"
          loading={creatingConnector}
          disabled={creatingConnector}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Horizontal>
    </Vertical>
  );
};

import first from 'lodash/first';
import get from 'lodash/get';

export const extractMkvFolderFilePreviewData = (data: any) => {
  const resourceId = get(data, 'ResourceID', '');
  const fileType = get(data, 'Type', '');
  const filePath = first(get(data, 'Path', '').split('.')) as string;

  return { resourceId, fileType, filePath };
};

import { JsonFormsRendererRegistryEntry } from '@jsonforms/core';
import { CheckboxControl, checkboxControlTester } from './controls/CheckboxControl';
import { CheckboxGroupControl, checkboxGroupControlTester } from './controls/CheckboxGroupControl';
import {
  DataSourceUploadControl,
  dataSourceUploadControlTester,
} from './controls/DataSourceUploadControl';
import { DropzoneControl, dropzoneControlTester } from './controls/DropzoneControl';
import {
  FetchTextFromApiControl,
  fetchTextFromApiControlTester,
} from './controls/FetchTextFromApiControl';
import {
  FolderDataSourceUploadControl,
  folderDataSourceUploadControlTester,
} from './controls/FolderDataSourceUploadControl';
import { ListObjectControl, listObjectControlTester } from './controls/ListControl';
import { MultiSelectControl, multiSelectControlTester } from './controls/MultiSelectControl';
import { NumberControl, numberControlTester } from './controls/NumberControl';
import { ObjectControl, objectControlTester } from './controls/ObjectControl';
import { PasswordInputControl, passwordInputControlTester } from './controls/PasswordInputControl';
import { RadioControl, radioControlTester } from './controls/RadioControl';
import {
  ResourceLookupControl,
  resourceLookupControlTester,
} from './controls/ResourceLookupControl';
import {
  ResourceLookupWithListControl,
  resourceLookupWithListControlTester,
} from './controls/ResourceLookupWithListControl';
import {
  SelectEnumControl,
  SelectOneOfControl,
  selectEnumControlTester,
  selectOneOfControlTester,
} from './controls/SelectControl';
import { SliderControl, sliderControlTester } from './controls/SliderControl';
import { TableControl, tableControlTester } from './controls/TableControl';
import { TextAreaControl, textAreaControlTester } from './controls/TextAreaControl';
import { TextControl, textControlTester } from './controls/TextControl';
import {
  GoogleSheetsGroupControl,
  googleSheetsGroupControlTester,
} from './groups/GoogleSheetsGroupControl';
import { GridLayoutControl, gridControlTester } from './layouts/GridControl';
import { HorizontalLayoutControl, horizontalControlTester } from './layouts/HorizontalControl';
import {
  SectionWithHelpLayoutControl,
  sectionWithHelpControlTester,
} from './layouts/SectionWithHelpControl';
import { VerticalLayoutControl, verticalControlTester } from './layouts/VerticalControl';

export const renderers: JsonFormsRendererRegistryEntry[] = [
  {
    renderer: TextControl,
    tester: textControlTester,
  },
  {
    renderer: NumberControl,
    tester: numberControlTester,
  },
  {
    renderer: SliderControl,
    tester: sliderControlTester,
  },
  {
    renderer: CheckboxControl,
    tester: checkboxControlTester,
  },
  {
    renderer: CheckboxGroupControl,
    tester: checkboxGroupControlTester,
  },
  {
    renderer: SelectOneOfControl,
    tester: selectOneOfControlTester,
  },
  {
    renderer: SelectEnumControl,
    tester: selectEnumControlTester,
  },
  {
    renderer: MultiSelectControl,
    tester: multiSelectControlTester,
  },
  {
    renderer: ResourceLookupControl,
    tester: resourceLookupControlTester,
  },
  {
    renderer: ResourceLookupWithListControl,
    tester: resourceLookupWithListControlTester,
  },
  {
    renderer: DataSourceUploadControl,
    tester: dataSourceUploadControlTester,
  },
  {
    renderer: FolderDataSourceUploadControl,
    tester: folderDataSourceUploadControlTester,
  },
  {
    renderer: RadioControl,
    tester: radioControlTester,
  },
  {
    renderer: ListObjectControl,
    tester: listObjectControlTester,
  },
  {
    renderer: ObjectControl,
    tester: objectControlTester,
  },
  {
    renderer: TextAreaControl,
    tester: textAreaControlTester,
  },
  {
    renderer: PasswordInputControl,
    tester: passwordInputControlTester,
  },
  {
    renderer: FetchTextFromApiControl,
    tester: fetchTextFromApiControlTester,
  },
  {
    renderer: DropzoneControl,
    tester: dropzoneControlTester,
  },
  {
    renderer: TableControl,
    tester: tableControlTester,
  },
  // layouts
  {
    renderer: VerticalLayoutControl,
    tester: verticalControlTester,
  },
  {
    renderer: SectionWithHelpLayoutControl,
    tester: sectionWithHelpControlTester,
  },
  {
    renderer: HorizontalLayoutControl,
    tester: horizontalControlTester,
  },
  {
    renderer: GridLayoutControl,
    tester: gridControlTester,
  },
  // groups
  {
    renderer: GoogleSheetsGroupControl,
    tester: googleSheetsGroupControlTester,
  },
];

import noop from 'lodash/noop';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { SelectedAnalysisModel } from '../../../components/snippets/detail/context/SnippetContext';
import { MLModelClass } from '../../../generated/api';

interface AddChartsContextData {
  currSelectedDataset: string;
  selectCurrDataset: (datasetId: string) => void;

  selectedCharts: SelectedAnalysisModel[];
  toggleChartSelection: (metadata: SelectedAnalysisModel) => void;

  selectedEvaluationClassType: MLModelClass | null;
  setSelectedEvaluationClassType: (modelClassType: MLModelClass) => void;
}

const initialContext = {
  currSelectedDataset: '',
  selectCurrDataset: noop,

  selectedCharts: [],
  toggleChartSelection: noop,

  selectedEvaluationClassType: null,
  setSelectedEvaluationClassType: noop,
};

const AddChartsContext = createContext<AddChartsContextData>(initialContext);

export const useAddChartsContext = () => useContext(AddChartsContext);

export const AddChartsContextProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const [currSelectedDataset, selectCurrDataset] = useState(initialContext.currSelectedDataset);
  const [selectedCharts, setSelectedCharts] = useState<AddChartsContextData['selectedCharts']>(
    initialContext.selectedCharts,
  );
  const [selectedEvaluationClassType, setSelectedEvaluationClassType] = useState<
    AddChartsContextData['selectedEvaluationClassType']
  >(initialContext.selectedEvaluationClassType);

  const toggleChartSelection = (metadata: SelectedAnalysisModel) => {
    if (selectedCharts.some(({ id }) => id === metadata.id)) {
      setSelectedCharts(prevSelectedCharts =>
        prevSelectedCharts.filter(({ id }) => id !== metadata.id),
      );
    } else {
      setSelectedCharts(prevSelectedCharts => [...prevSelectedCharts, metadata]);
    }
  };

  const value = {
    currSelectedDataset,
    selectCurrDataset,

    selectedCharts,
    toggleChartSelection,

    selectedEvaluationClassType,
    setSelectedEvaluationClassType,
  };

  return <AddChartsContext.Provider value={value}>{children}</AddChartsContext.Provider>;
};

import { mergeAttributes, textblockTypeInputRule } from '@tiptap/core';
import Paragraph from '@tiptap/extension-paragraph';
import { mergeAndStringifyStyles } from '../../utils/style-utils';

export interface ParagraphOptions {
  HTMLAttributes: Record<string, any>;
}

export enum TextAlignment {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify',
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    HTMLParagraph: {
      /**
       * Toggle a paragraph
       */
      setParagraph: () => ReturnType;
      setParagraphAlign: (alignment: TextAlignment) => ReturnType;
      unsetParagraphAlign: () => ReturnType;

      setParagraphStyleClass: (className?: string) => ReturnType;
    };
  }
}

const HEADING_LEVELS = [1, 2, 3, 4, 5, 6];

export const HTMLParagraph = Paragraph.extend<ParagraphOptions>({
  name: 'paragraph',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',

  content: 'inline*',

  addAttributes() {
    return {
      'data-id': {
        default: null,
      },
      class: {
        default: null,
        parseHTML: element => {
          const className = element.getAttribute('class');
          return className && className.trim() ? className : null;
        },
      },
      style: {
        default: null,
        parseHTML: element => {
          const style = element.getAttribute('style');
          return style && style.trim() ? style : null;
        },
      },
      textAlign: {
        default: null,
        parseHTML: element => {
          const alignment = element.style.textAlign;
          return alignment in TextAlignment ? alignment : null;
        },
      },
    };
  },

  parseHTML() {
    return [{ tag: 'p' }];
  },

  renderHTML({ HTMLAttributes }) {
    const newStyles: Record<string, string> = {};

    if (HTMLAttributes.textAlign) {
      newStyles['text-align'] = HTMLAttributes.textAlign;
    }

    const styleString = mergeAndStringifyStyles(HTMLAttributes.style, newStyles);
    const attrs = { ...this.options.HTMLAttributes };

    if (HTMLAttributes.class) {
      attrs.class = HTMLAttributes.class;
    }
    if (styleString) {
      attrs.style = styleString;
    }
    if (HTMLAttributes['data-id']) {
      attrs['data-id'] = HTMLAttributes['data-id'];
    }

    return ['p', mergeAttributes(attrs), 0];
  },

  addCommands() {
    return {
      setParagraph:
        () =>
        ({ commands }) =>
          commands.setNode(this.name),
      setParagraphAlign:
        (alignment: string) =>
        ({ commands }) => {
          if (!(alignment in TextAlignment)) {
            return false;
          }
          return commands.updateAttributes(this.name, { textAlign: alignment });
        },
      unsetParagraphAlign:
        () =>
        ({ commands }) =>
          commands.resetAttributes(this.name, 'textAlign'),
      setParagraphStyleClass:
        (className?: string) =>
        ({ commands }) =>
          commands.updateAttributes(this.name, { class: className }),
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-l': () => this.editor.commands.setParagraphAlign(TextAlignment.left),
      'Mod-Shift-e': () => this.editor.commands.setParagraphAlign(TextAlignment.center),
      'Mod-Shift-r': () => this.editor.commands.setParagraphAlign(TextAlignment.right),
      'Mod-Shift-j': () => this.editor.commands.setParagraphAlign(TextAlignment.justify),
    };
  },

  addInputRules() {
    return HEADING_LEVELS.map(level =>
      textblockTypeInputRule({
        find: new RegExp(`^(#{1,${level}})\\s$`),
        type: this.type,
        getAttributes: {
          class: `Heading${level}`,
          textAlign: 'left',
        },
      }),
    );
  },
});

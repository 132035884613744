import { TooltipFormatterContextObject, numberFormat } from 'highcharts';
import { TooltipContainer, TooltipHeader, TooltipItemRow } from '../ChartTooltip.style';

interface ColumnTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

export const ColumnTooltip = ({ formatterContext }: ColumnTooltipProps) => {
  const total = formatterContext.series.data.map(p => p.y || 0).reduce((a, b) => a + b, 0);
  const value = formatterContext.y;
  const percentage = 100 * (value / total);

  return (
    <TooltipContainer>
      <TooltipHeader>{formatterContext.point.category}</TooltipHeader>
      <TooltipItemRow>{value}</TooltipItemRow>
      <TooltipItemRow>{numberFormat(percentage, 2)}%</TooltipItemRow>
    </TooltipContainer>
  );
};

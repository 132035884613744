import { AddResourceButtonContainer } from './AddResourceButton.container';
import { ResourceActionsProps } from './util';

// Allow add as data sink for supported resources
export const DataSinkResourceActions = ({
  connectorId,
  connectorType,
  resourcePath,
  isAdded,
  onResourceAdd,
}: ResourceActionsProps) => {
  const addSinkButtonLabel = isAdded ? 'Added' : '+ Add Resource';

  return (
    <AddResourceButtonContainer
      connectorId={connectorId}
      connectorType={connectorType}
      resourcePath={resourcePath}
      onResourceAdd={onResourceAdd}
      isDataSink={true}
      disabled={isAdded}
      w={150}
    >
      {addSinkButtonLabel}
    </AddResourceButtonContainer>
  );
};

import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';
import transform from 'lodash/transform';
import { AppIdType, DocumentStatusType, DocumentType } from '../../../../generated/api';

export const getColorFromStatus = (status: DocumentStatusType) => {
  switch (status) {
    case DocumentStatusType.InProgress:
      return 'yellow.6';
    case DocumentStatusType.Failed:
      return 'red.6';
    case DocumentStatusType.Finished:
    default:
      return 'blue.6';
  }
};

export const getButtonTextFromAppId = (appId: AppIdType) => {
  switch (appId) {
    case AppIdType.Summarization:
      return 'Summarize';
    case AppIdType.CopyEdit:
    default:
      return "Let's begin";
  }
};

export const permittedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
];

export const getFileTypeFromMimeType = (fileType: string) => {
  switch (fileType) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return DocumentType.Docx;
    case 'application/pdf':
      return DocumentType.Pdf;
    default:
      throw 'Unhandled document type encountered';
  }
};

export const getLLMDisplayName = (llmType: string) => llmType.replaceAll('_', ' ');

export const toCamelCaseKeys = (obj: { [key: string]: any }): { [key: string]: any } =>
  transform(
    obj,
    (result: Record<string, any>, value: any, key: string) => {
      const camelCaseKey = camelCase(key);

      result[camelCaseKey] = isPlainObject(value)
        ? toCamelCaseKeys(value)
        : Array.isArray(value)
        ? value.map((item: any) => (isPlainObject(item) ? toCamelCaseKeys(item) : item))
        : value;

      return result;
    },
    {},
  );

// TODO: See if we can remove variants and use appId from URL
export const getAppVariantFromAppId = (appId: string): AppIdType | undefined => {
  switch (appId) {
    case 'copy-edit':
      return AppIdType.CopyEdit;
    case 'summarization':
      return AppIdType.Summarization;
    case 'doc-qna':
      return AppIdType.QnaWithDoc;
    default:
      return undefined;
  }
};

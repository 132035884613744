import { useState } from 'react';
import { Button, ButtonProps, notifications } from '../../../../design-system/v2';
import { ResourceRequest, StorageType } from '../../../../generated/api';
import {
  useCreateDataSourceFromResourceMutation,
  useGetAirbyteConnectorJsonQuery,
  useInvalidateListAllResourcesQuery,
} from '../../../../queries/connectors';
import { AddResourceWithStartDate } from './AddResourceWithStartDate';

const DEFAULT_BUTTON_WIDTH = 160;

interface AddResourceButtonContainerProps extends ButtonProps {
  connectorId: string;
  connectorType: StorageType;
  resourcePath: ResourceRequest[];
  onResourceAdd?: (resourcePath: string) => void;
  isDataSink?: boolean;
}
const hasStartDateField = (resourceData: any): boolean =>
  Object.keys(resourceData?.parameters?.additionalParams || {}).some(key =>
    key.includes('start_date'),
  );

export const AddResourceButtonContainer = ({
  connectorId,
  connectorType,
  resourcePath,
  onResourceAdd,
  isDataSink,
  w = DEFAULT_BUTTON_WIDTH,
  ...restProps
}: AddResourceButtonContainerProps): JSX.Element => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { mutate: createDataSource, isLoading: isCreatingSource } =
    useCreateDataSourceFromResourceMutation();
  const invalidateAllResources = useInvalidateListAllResourcesQuery(connectorId);

  const { data: resourceData } = useGetAirbyteConnectorJsonQuery(connectorType);
  const hasStartDate = hasStartDateField(resourceData);

  const handleAddResourceClick = (startDate?: string) => {
    const resourceListWithDate = startDate
      ? resourcePath.map(res => ({ ...res, start_date: startDate }))
      : resourcePath;

    createDataSource(
      { connectorId, isDataSink, resourceList: resourceListWithDate },
      {
        onError: () => {
          notifications.error('Error saving resource');
        },
        onSuccess: res => {
          notifications.success('Resource added successfully');
          onResourceAdd?.(res.data.response?.resourceId ?? '');
          invalidateAllResources();
        },
      },
    );
  };

  return (
    <>
      <Button
        variant="outline"
        shape="rounded"
        color="green"
        onClick={() => (hasStartDate ? setModalOpen(true) : handleAddResourceClick())}
        loading={isCreatingSource}
        w={w}
        {...restProps}
      >
        + Add Resource
      </Button>

      {hasStartDate && (
        <AddResourceWithStartDate
          onConfirm={handleAddResourceClick}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
};

import {
  GroupLayout,
  LayoutProps,
  RankedTester,
  and,
  optionIs,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import get from 'lodash/get';
import set from 'lodash/set';
import {
  ActionIcon,
  Box,
  Collapse,
  CopyButton,
  Horizontal,
  Text,
  Vertical,
} from '../../../../../design-system/v2';
import { AsyncDropDownResources, BaseAPIFilter, Operator } from '../../../../../generated/api';
import { isProduction } from '../../../../../lib/env.util';

const MKV_GSHEET_SERVICE_ACCOUNT_DEV = 'markovgooglesheet-dev@markovml-dev.iam.gserviceaccount.com';
const MKV_GSHEET_SERVICE_ACCOUNT_PROD =
  'markovgooglesheet-prod@markovml-prod.iam.gserviceaccount.com';

const MKV_GSHEET_SERVICE_ACCOUNT = isProduction()
  ? MKV_GSHEET_SERVICE_ACCOUNT_PROD
  : MKV_GSHEET_SERVICE_ACCOUNT_DEV;

const isValidSheetsUrl = (value: string) => {
  const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/.+/;
  return regex.test(value);
};

const SheetSharingNotice = () => (
  <Vertical
    spacing="sm"
    px="lg"
    py="md"
    bg="gray.0"
    sx={theme => ({
      border: `1px solid ${theme.colors.gray[2]}`,
      borderRadius: theme.radius.sm,
    })}
  >
    <Text variant="small02" color="gray.7">
      Your Google Sheets document must be publicly available or shared with the MarkovML service
      account below
    </Text>
    <Horizontal
      noWrap
      p="sm"
      bg="blue.0"
      sx={theme => ({
        border: `1px solid ${theme.colors.blue[4]}`,
        borderRadius: theme.radius.xs,
      })}
    >
      <Text variant="subTitle05" color="blue.7">
        {MKV_GSHEET_SERVICE_ACCOUNT}
      </Text>
      <CopyButton value={MKV_GSHEET_SERVICE_ACCOUNT} timeout={2000}>
        {({ copied, copy }) => (
          <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
            {copied ? <IconCheck size={24} /> : <IconCopy size={24} />}
          </ActionIcon>
        )}
      </CopyButton>
    </Horizontal>
  </Vertical>
);

const GoogleSheetsGroupComponent = (props: LayoutProps): JSX.Element => {
  const { path, data, uischema, schema, enabled, renderers, cells } = props;

  const groupUiSchema = uischema as GroupLayout;

  const gsheetUrl = data?.gsheet_url ?? '';
  const isValidUrl = isValidSheetsUrl(gsheetUrl);

  return (
    <>
      <Vertical spacing={groupUiSchema.options?.spacing ?? 24}>
        {groupUiSchema.elements.map((element, i) => {
          const appliedUiSchema = element;
          const isSheetLookup =
            element.options?.resourceLookupOptions?.resourceKey ===
            AsyncDropDownResources.GsheetWorksheets;

          if (isSheetLookup) {
            // Apply filter for resource lookup
            const filtersPath = 'options.resourceLookupOptions.filters';
            const existingFilters = get(appliedUiSchema, filtersPath, []) as BaseAPIFilter[];
            const filterIsPresent = existingFilters.some(
              filter => filter.field === 'gsheetUrl' && filter.value === gsheetUrl,
            );
            if (!filterIsPresent) {
              const urlFilter: BaseAPIFilter = {
                field: 'gsheetUrl',
                operator: Operator.Eq,
                value: gsheetUrl,
              };
              const nextFilters = [
                ...existingFilters.filter(filter => filter.field !== 'gsheetUrl'),
                urlFilter,
              ];
              set(appliedUiSchema, filtersPath, nextFilters);
            }
          }

          const dispatch = (
            <JsonFormsDispatch
              uischema={appliedUiSchema}
              schema={schema}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
            />
          );

          return (
            <Box key={`${path}_${i}`}>
              {isSheetLookup ? (
                <Collapse in={isValidUrl} transitionDuration={200}>
                  {/* Don't render dispatch if URL is invalid (to prevent errors) */}
                  {isValidUrl && dispatch}
                </Collapse>
              ) : (
                <Vertical spacing="sm">
                  {dispatch}
                  <SheetSharingNotice />
                </Vertical>
              )}
            </Box>
          );
        })}
      </Vertical>
    </>
  );
};

export const googleSheetsGroupControlTester: RankedTester = rankWith(
  1,
  and(uiTypeIs('Group'), optionIs('gsheet', true)),
);

export const GoogleSheetsGroupControl = withJsonFormsLayoutProps(GoogleSheetsGroupComponent);

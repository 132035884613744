import {
  Loader,
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
  SelectItem,
} from '@mantine/core';
import { ReactNode, forwardRef } from 'react';

export type SelectOption = SelectItem;

export interface SelectProps extends Omit<MantineSelectProps, 'data'> {
  options: (SelectOption | string)[];
  ariaLabel: string;
  label?: ReactNode;
  hideLabel?: boolean;
  icon?: ReactNode;
  loading?: boolean;
}

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  ({ ariaLabel, label, icon, loading, options, hideLabel = false, disabled, ...rest }, ref) => {
    if (!ariaLabel) {
      throw 'ariaLabel prop is a mandatory prop for input';
    }

    const displayedLabel = hideLabel ? null : label ?? ariaLabel;

    return (
      <MantineSelect
        ref={ref}
        aria-label={ariaLabel}
        {...rest}
        label={displayedLabel}
        icon={loading ? <Loader size="sm" variant="dots" /> : icon ?? null}
        disabled={loading || disabled}
        data={options}
      />
    );
  },
);

Select.displayName = 'Select';

import { CSSProperties } from 'react';
import { BASE_PX_VALUE } from '../../../theme/constants';
import { fontFamily } from '../../../theme/font';
import { FontWeight, LineHeight, fontWeights, lineHeight } from '../constants';

interface MantineHeadingStyle {
  fontSize: string;
  fontWeight: CSSProperties['fontWeight'];
  lineHeight: CSSProperties['lineHeight'];
}

interface MantineHeadings {
  headings: {
    fontFamily: CSSProperties['fontFamily'];
    fontWeight: CSSProperties['fontWeight'];
    sizes: {
      h1: MantineHeadingStyle;
      h2: MantineHeadingStyle;
      h3: MantineHeadingStyle;
      h4: MantineHeadingStyle;
    };
  };
}

export const headingTheme: MantineHeadings = {
  headings: {
    fontFamily: fontFamily,
    fontWeight: 'regular',
    sizes: {
      h1: {
        fontSize: `${48 / BASE_PX_VALUE}rem`,
        fontWeight: fontWeights[FontWeight.SEMI_BOLD],
        lineHeight: lineHeight[LineHeight.XXXL],
      },
      h2: {
        fontSize: `${28 / BASE_PX_VALUE}rem`,
        fontWeight: fontWeights[FontWeight.SEMI_BOLD],
        lineHeight: lineHeight[LineHeight.XXL],
      },
      h3: {
        fontSize: `${24 / BASE_PX_VALUE}rem`,
        fontWeight: fontWeights[FontWeight.SEMI_BOLD],
        lineHeight: lineHeight[LineHeight.XL],
      },
      h4: {
        fontSize: `${20 / BASE_PX_VALUE}rem`,
        fontWeight: fontWeights[FontWeight.SEMI_BOLD],
        lineHeight: lineHeight[LineHeight.XL],
      },
    },
  },
};

import pick from 'lodash/pick';
import { ReactElement, forwardRef } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

export type ChildrenRowProps = Partial<ListChildComponentProps>;

interface VirtualizedTableProps<T = any> {
  itemCount: number;
  /**
   * itemSize: This prop tells react-window what size an item of the list
   * is going to be. Currently we will only support fixed height table, but react-window supports
   * variable sized rows too.
   * We should avoid variable size as much as possible, because we will have to calculate
   * content height.
   * Current constraint:
   * calculate the size of the content (padding, margins accounting all of that) and pass this number.
   * React-window will use this number to calculate the row height and apply windowing accordingly
   */
  itemSize: number;
  children: (
    p: Partial<ChildrenRowProps>,
  ) => ReactElement<ChildrenRowProps & Record<string, unknown>>;
}

export const VirtualizedTable = forwardRef(
  <D,>(
    { itemCount, itemSize, children }: VirtualizedTableProps<D>,
    ref: React.ForwardedRef<any>,
  ) => {
    const Row = (props: ListChildComponentProps) => (
      <div style={props.style}>
        {children({
          ...pick(props, ['index', 'isScrolling']),
        })}
      </div>
    );

    return (
      <AutoSizer>
        {({ width, height }: Size) => (
          <FixedSizeList
            height={height}
            itemCount={itemCount}
            itemSize={itemSize}
            width={width}
            useIsScrolling
            ref={ref}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  },
);

VirtualizedTable.displayName = 'VirtualizedTable';

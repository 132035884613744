import { MantineTheme } from '@mantine/core';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useMemo } from 'react';
import { DIFF_TYPE } from '../../../../../components/common/constants';
import { CitationPositionType } from '../../../../../generated/api';
import {
  useGetAllCitationsTextQuery,
  useGetDocumentMetadataQuery,
} from '../../../../../queries/document-ai/copyEdit';
import { Box, CSSObject, Skeleton } from '../../../core';
import { InlineCitationComponent, ReferenceComponent } from './MarkovReferenceItem';
import { getAppliedCitationStyle, tryParseJSONObject } from './util';

export function getCitationsIdsArray(stringifiedCitations: string) {
  if (tryParseJSONObject(stringifiedCitations)) {
    return JSON.parse(stringifiedCitations);
  }
}

const getStyles = (highlightType: number, selected: boolean, theme: MantineTheme): CSSObject => {
  const highlightBorderMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[3],
    [DIFF_TYPE.EQUAL]: theme.colors.gray[3],
    [DIFF_TYPE.DELETE]: theme.colors.red[3],
  };

  const highlightBackgroundMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[0],
    [DIFF_TYPE.EQUAL]: '',
    [DIFF_TYPE.DELETE]: theme.colors.red[0],
  };

  return {
    cursor: 'pointer',
    userSelect: 'none',
    borderRadius: '4px',
    display: 'inline-block',
    borderStyle: 'dashed',
    borderColor: highlightBorderMap[highlightType],
    backgroundColor: selected
      ? theme.fn.themeColor('blue.1')
      : highlightBackgroundMap[highlightType],
    ...theme.fn.hover({
      backgroundColor: selected ? theme.fn.themeColor('blue.1') : theme.fn.themeColor('gray.1'),
    }),
  };
};

enum OLD_CITATION_FORMAT {
  IN_TEXT = 'INLINE',
  BIBLIOGRAPHY = 'BIBLIOGRAPHY',
}

export const MarkovReferenceComponentContainer: React.FC<NodeViewProps> = ({
  editor,
  node,
  getPos,
  selected,
}) => {
  /**
   * node.attrs is set when doing addCitationToEditor. Please check implementation there
   * Currently node.attrs = {
   *  documentId: string;
   *  referenceIds: string[]
   * }
   */
  const citationIds: string[] = useMemo(
    () => getCitationsIdsArray(node.attrs.referenceIds) || [],
    [node.attrs.referenceIds],
  );
  const documentId: string = node.attrs.documentId ?? '';
  // Backward compatibility for citationFormat
  const citationFormat =
    node.attrs.format === OLD_CITATION_FORMAT.IN_TEXT
      ? CitationPositionType.InText
      : node.attrs.format === OLD_CITATION_FORMAT.BIBLIOGRAPHY
      ? CitationPositionType.Bibliography
      : node.attrs.format;

  const isInlineCitation = citationFormat === CitationPositionType.InText;

  const { data: documentMetadata } = useGetDocumentMetadataQuery(documentId);
  const citationStyle = getAppliedCitationStyle(documentMetadata?.citationStyle);

  const { data: documentReferences } = useGetAllCitationsTextQuery(
    documentId,
    citationFormat,
    citationStyle,
  );

  const { data: documentFullReferences } = useGetAllCitationsTextQuery(
    documentId,
    CitationPositionType.Bibliography,
    citationStyle,
  );

  const citations = useMemo(
    () =>
      documentReferences?.citationTexts.filter(({ citationId = '' }) =>
        citationIds.includes(citationId),
      ) ?? [],
    [documentReferences?.citationTexts, citationIds],
  );

  const fullTextCitations = useMemo(
    () =>
      documentFullReferences?.citationTexts.filter(({ citationId = '' }) =>
        citationIds.includes(citationId),
      ) ?? [],
    [documentFullReferences?.citationTexts, citationIds],
  );

  const highlightType = node.attrs.highlightType;

  const handleCitationClick = () => {
    const position = getPos();
    editor.commands.setNodeSelection(position);
  };

  if (!citations.length || !fullTextCitations.length) {
    return (
      <NodeViewWrapper as="span">
        <Skeleton
          h={10}
          w={isInlineCitation ? 60 : 200}
          my={isInlineCitation ? 0 : 'xs'}
          sx={isInlineCitation ? { display: 'inline-block' } : {}}
        />
      </NodeViewWrapper>
    );
  }

  if (!isInlineCitation && citations && citations.length > 0) {
    return (
      <NodeViewWrapper as="span" draggable>
        <Box
          citation-id={citations[0].citationId}
          onClick={handleCitationClick}
          px="md"
          py="xs"
          sx={theme => getStyles(highlightType, selected, theme)}
        >
          <ReferenceComponent referenceObj={citations[0]} />
        </Box>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper as="span" draggable>
      <InlineCitationComponent
        citations={citations}
        fullCitations={fullTextCitations}
        highlightType={highlightType}
      />
    </NodeViewWrapper>
  );
};

import pickBy from 'lodash/pickBy';
import { Location, createSearchParams } from 'react-router-dom';

export const isHistoryEmpty = (location: Location) => location.key === 'default';

export const createQueryParams = (queryParams = {}) => {
  // pickBy filters out any params with value of undefined
  const queryParamString = createSearchParams(pickBy(queryParams)).toString();
  return queryParamString ? `?${queryParamString}` : '';
};

export const cleanErrorsInSearchParams = (searchParams?: string): string => {
  if (!searchParams) return '';

  const queryParams = new URLSearchParams(searchParams);
  if (queryParams.has('error')) {
    queryParams.delete('error');
    queryParams.delete('error_description');

    // Also removing this, so Auth0 can fetch new state and code.
    // Mismatch here causes issues in redirection
    queryParams.delete('state');
    queryParams.delete('code');
  }
  return `?${queryParams.toString()}`;
};

import { WorkflowEdge, WorkflowNode } from '../nodes/utils';

export enum Mode {
  Debug = 'Debug',
  Build = 'Build',
}

export enum RunType {
  DEBUG = 'debug',
  RUN = 'run',
}

const SUPPORTED_AUDIO_EXTENSIONS = ['wav', 'mp3'];
export const AUDIO_URL_REGEX = new RegExp(
  `^https?:\\/\\/[^\\s]+\\.(${SUPPORTED_AUDIO_EXTENSIONS.join('|')})$`,
);

/**
 * Finds the source node IDs for a given node ID in a DAG.
 * @param graph The directed acyclic graph.
 * @param nodeId The node ID for which to find the source nodes.
 * @returns An array of source node IDs or a message if the node is a root.
 */
export const findSourceNodes = (
  graph: { nodes: WorkflowNode[]; edges: WorkflowEdge[] },
  nodeId: string,
): string[] => {
  const sourceIds: string[] = graph.edges
    .filter(edge => edge.target === nodeId)
    .map(edge => edge.source);

  return sourceIds;
};

export const getErrorMessageFromCode = (code: string) => {
  switch (code) {
    case 'MULTIPLE_CONNECTED_COMPONENTS':
      return 'Unable to schedule workflow since there are multiple connected components. Please fix and try again.';
    case 'CYCLE_DETECTED':
      return 'Unable to schedule workflow since there is a cycle present. Please fix and try again.';
    case 'NO_NODE_FOUND':
      return 'Unable to schedule workflow since there are no components. Please fix and try again.';
    case 'NO_EDGES_FOUND':
      return 'Unable to schedule workflow since there are no connected components. Please fix and try again.';
    case 'INVALID_DAG':
      return 'Unable to schedule the workflow due to incorrect workflow structure. Please ensure all nodes are properly connected.';
    case 'CRON_EMPTY':
      return 'Unable to schedule workflow because frequency is missing. Please provide all required fields and try again.';
    case 'INVALID_CRON':
      return 'Unable to schedule workflow because the provided frequency is invalid. Please provide all required fields and try again.';
    case 'INTERVAL_EMPTY':
      return 'Unable to schedule workflow because the interval is missing. Please provide a valid interval.';
    case 'INVALID_INTERVAL':
      return 'Unable to schedule workflow because the interval is too short. Please ensure the interval is at least 30 minutes.';
    case 'DATES_EMPTY':
      return 'Unable to schedule workflow because the start or end date is missing. Please provide valid dates.';
    case 'INVALID_DATES':
      return 'Unable to schedule workflow because the start date is after the end date. Please correct the dates and try again.';
    case 'INVALID_DATES_INTERVAL':
      return 'Unable to schedule workflow because the duration between the start and end dates is too short. Please ensure the duration is at least 30 minutes.';
    default:
      return 'There was an issue scheduling your workflow. Please check your flow and try again.';
  }
};

import startCase from 'lodash/startCase';
import { LineChartIllustration } from '../../../../../../design-system';
import { VisualizationType } from '../../../../../../design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { useExperimentMetricsListQuery } from '../../../../../../queries/experiments/experiments';
import { getChartsData } from '../util';

export const useGetExperimentCharts = (experimentId: string) => {
  const { isLoading, data: metricKeys } = useExperimentMetricsListQuery(experimentId);

  const chartsDetails = (metricKeys ?? []).map(metricKey =>
    getChartsData({
      visualizationType: VisualizationType.EXPERIMENT_CHART,
      title: startCase(metricKey.split('_').join(' ')),
      chart: <LineChartIllustration />,
      visualizationParameters: { experimentId, metricKey },
    }),
  );

  return { isLoading, chartsDetails };
};

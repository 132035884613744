import { Skeleton } from '@mantine/core';
import noop from 'lodash/noop';
import range from 'lodash/range';
import { ReactNode } from 'react';
import { useAddChartsContext } from '../../../../../contexts/snippets/add-charts/AddChartsContext';
import {
  Box,
  Card,
  Checkbox,
  Horizontal,
  ScrollArea,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
} from '../../../../../design-system/v2';
import { SelectItem } from '../../../../../design-system/v2/core/inputs/multi-select/mantine/Select/types';
import { SegmentType } from '../../../../../generated/api';
import { getSegmentText } from '../../../../analyser/analyser.util';
import { isSplitSegmentType } from '../../../../dataset-registration/util';
import { SelectedAnalysisModel } from '../../context/SnippetContext';

export interface ChartsDetails extends SelectedAnalysisModel {
  title?: string;
  subTitle?: string;
  chart?: ReactNode;
  bgColor?: string;
}

interface ChartsListProps {
  gridCols?: number;
  charts?: ChartsDetails[];
  segments?: string[];
  selectedSegment?: string;
  setSelectedSegment?: (segType: string) => void;
  variables?: SelectItem[];
  selectedVariable?: string;
  setSelectedVariable?: (variableName: string) => void;
  isLoading?: boolean;
}

const TextContainerHeight = 52;

export const ChartCard = (chartDetails: ChartsDetails): JSX.Element => {
  const { selectedCharts, toggleChartSelection } = useAddChartsContext();
  const { id, chart, title, subTitle, bgColor, metadata } = chartDetails;

  const handleVizClick = () => toggleChartSelection({ id, metadata });

  // Hide subtitle for "unknown"/"all" segments
  const displayedSubtitle =
    subTitle && isSplitSegmentType(subTitle as SegmentType) ? getSegmentText(subTitle) : undefined;

  return (
    <Card
      data-testid="snippets-add-chart-sidebar-card"
      onClick={handleVizClick}
      pos="relative"
      h={200}
      w="100%"
      p={0}
      shadow="md"
      withBorder
      bg={bgColor}
      sx={{ cursor: 'pointer' }}
    >
      <Checkbox
        checked={selectedCharts.some(({ id: sId }) => sId === id)}
        onChange={noop}
        pos="absolute"
        right={8}
        top={8}
        color="green.6"
        radius="xl"
        size="md"
      />
      <Box h={`calc(100% - ${TextContainerHeight}px)`} p="sm" sx={{ overflow: 'hidden' }}>
        {chart}
      </Box>
      <Box
        h={TextContainerHeight}
        bg="white.0"
        sx={theme => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <Tooltip label={title} multiline>
          <Text pt={4} px="sm" variant="small01" color="gray.7" lineClamp={1}>
            {title}
          </Text>
        </Tooltip>
        {displayedSubtitle && (
          <Text pb={4} px="sm" variant="small01" color="gray.5">
            {displayedSubtitle}
          </Text>
        )}
      </Box>
    </Card>
  );
};

export const ChartsList = ({
  charts = [],
  segments = [],
  selectedSegment,
  setSelectedSegment,
  variables = [],
  selectedVariable,
  setSelectedVariable,
  gridCols = 3,
  isLoading = false,
}: ChartsListProps): JSX.Element => (
  <ScrollArea p="md" w="70%" h="calc(100vh - 120px)" pos="relative">
    {!isLoading && (
      <Horizontal mb="lg" position="right">
        {segments.length > 0 && isSplitSegmentType(segments[0] as SegmentType) && (
          <Select
            value={selectedSegment}
            onChange={setSelectedSegment}
            ariaLabel="Segments"
            placeholder="Select Segment"
            options={segments.map(segment => ({ value: segment, label: getSegmentText(segment) }))}
          />
        )}
        {variables.length > 0 && (
          <Select
            value={selectedVariable}
            onChange={setSelectedVariable}
            ariaLabel="Variables"
            placeholder="Select Variable"
            options={variables}
          />
        )}
      </Horizontal>
    )}

    <SimpleGrid cols={isLoading ? 3 : gridCols}>
      {isLoading
        ? range(9).map(i => <Skeleton key={i} h={200} />)
        : charts.map(chart => <ChartCard key={chart.id} {...chart} />)}
    </SimpleGrid>
  </ScrollArea>
);

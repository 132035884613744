import { TabProps as MantineTabProps, Tabs as MantineTabs, TabsProps } from '@mantine/core';
import { useStyles } from './Tab.style';

export type { TabsProps } from '@mantine/core';

const Tabs = (props: TabsProps) => <MantineTabs color="primary.8" {...props} />;

Tabs.List = MantineTabs.List;
Tabs.Panel = MantineTabs.Panel;

export type TabSize = 'sm' | 'md';

export interface TabProps extends MantineTabProps {
  size?: TabSize;
}

const Tab = ({ children, size = 'md', className, ...restProps }: TabProps) => {
  const { classes, cx } = useStyles({ size });

  return (
    <MantineTabs.Tab className={cx(classes.tab, className)} {...restProps}>
      {children}
    </MantineTabs.Tab>
  );
};

Tabs.Tab = Tab;

export { Tabs };

import { useTimeout } from '@mantine/hooks';
import { useCss } from '@mantine/styles';
import { IconGripVertical, IconPlus } from '@tabler/icons-react';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import get from 'lodash/get';
import { useEffect } from 'react';
import { useSnippetDetail } from '../../../../../components/snippets/detail/context/SnippetContext';
import { logger } from '../../../../../initializers/logging';
import { theme } from '../../../theme/theme';
import './draggable-block.css';

export const DraggableBlockItem = ({
  editor,
  node,
  getPos,
  deleteNode,
  selected,
}: NodeViewProps) => {
  const { cx } = useCss();
  const { editing } = useSnippetDetail();
  const { start: openCommandsMenu } = useTimeout(() => {
    const transaction = editor.state.tr.insertText('/');
    editor.view.dispatch(transaction);
  }, 0);

  const getCurrentPos = () => getPos() + node.nodeSize;

  const handleAddText = () => {
    const isTextBlock = get(node, 'attrs.data-blocktype') === 'text';

    if (isTextBlock && !node.textContent) {
      // Insert '/' to open commands menu
      editor.commands.focus(getPos() + 2);
      openCommandsMenu();
      return;
    }

    if (isTextBlock && node.textContent === '/') {
      // Focus at end so as to reopen commands menu
      editor.commands.focus(getPos() + 3);
      return;
    }

    editor.commands.setTextBlock({ position: getCurrentPos() });
    openCommandsMenu();
  };

  const blockId = node.attrs['data-block-id'];

  useEffect(() => {
    const blockType = get(node, 'attrs.data-blocktype');
    const isChartBlock = blockType === 'chart-grid';
    const isEmptyChartBlock =
      JSON.parse(get(node.content.lastChild, 'attrs.data-grid') ?? '[]').length === 0;

    const isEmbeddingBlock = blockType === 'embedding-chart';
    const isEmptyEmbeddingBlock = !Boolean(
      get(node.content.lastChild, 'attrs.data-dataset-id') ?? '',
    );

    try {
      if ((isChartBlock && isEmptyChartBlock) || (isEmbeddingBlock && isEmptyEmbeddingBlock)) {
        deleteNode();
      }
    } catch (err) {
      logger.error(`Got empty block and unable to delete it ${JSON.stringify(err)}`);
    }
  }, [deleteNode, node]);

  return (
    <NodeViewWrapper className="react-component-with-content" data-block-id={blockId} draggable>
      {editing && (
        <>
          <span className="label" contentEditable={false}>
            <IconPlus
              width={21}
              height={21}
              color={theme.colors?.gray ? theme.colors?.gray[6] : ''}
              onClick={handleAddText}
            />
          </span>
          <span className="label" contentEditable={false} data-drag-handle>
            <IconGripVertical stroke={theme.colors?.gray ? theme.colors?.gray[6] : ''} />
          </span>
        </>
      )}

      <NodeViewContent
        className={cx('content', {
          ['not-editable']: !editing,
          ['selected']: selected,
        })}
      />
    </NodeViewWrapper>
  );
};

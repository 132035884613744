import { IconCheck, IconX } from '@tabler/icons-react';
import chunk from 'lodash/chunk';
import noop from 'lodash/noop';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ulid } from 'ulid';
import {
  Accordion,
  Affix,
  Box,
  Card,
  FileWithPath,
  Flex,
  Horizontal,
  Loader,
  Text,
} from '../../design-system/v2';
import { rem } from '../../design-system/v2/styles';
import { PostUrl, Urls } from '../../generated/api';
import { CompleteParams, useUploadToS3 } from '../../queries/data-upload';

export interface FileData {
  file: File;
  uploadParams: Urls | PostUrl;
}

export enum UploadState {
  UPLOAD_STARTED = 'Uploading',
  UPLOAD_ERRORED = 'Errored',
  UPLOAD_SUCCESS = 'Success',
}

export interface DataUploadDetails {
  id: string;
  fileData: FileData[];
  state: UploadState;
  useUploadWindow?: boolean;
  additionalData?: Record<string, unknown>;
}

interface UploadOptions {
  // TODO:: remove once datasets is deprecated
  useUploadWindow?: boolean;
  additionalData?: Record<string, unknown>;
  onFileUploadComplete: (completeUploadParams: CompleteParams) => Promise<void>;
  onSuccess: () => void;
  onError: (err: string) => void;
  fetchPresignedUrls: (files: File[]) => Promise<FileData[]>;
}

interface DataUpload {
  uploads: DataUploadDetails[];
  addUpload: (id: string, fileData: File[], uploadOptions: UploadOptions) => void;
  abortUpload: (id: string) => void;
  removeUpload: (id: string) => void;
}

export const BATCH_SIZE = 10;

const initialContext: DataUpload = {
  uploads: [],
  addUpload: noop,
  abortUpload: noop,
  removeUpload: noop,
};

const DataUploadContext = createContext<DataUpload>(initialContext);

export const useDataUpload = () => useContext(DataUploadContext);

export const DataUploadProvider = ({ children }: PropsWithChildren<Record<never, unknown>>) => {
  const [uploads, setUploads] = useState<DataUploadDetails[]>([]);
  const uploadTos3 = useUploadToS3();

  const handleBrowserTabClose = useCallback(
    async (evt: BeforeUnloadEvent) => {
      if (uploads.length > 0) {
        evt.preventDefault();

        return (evt.returnValue = 'File is being uploaded');
      }
    },
    [uploads],
  );

  const updateUploadState = (id: string, state: UploadState) => {
    setUploads(uploads =>
      uploads.map(upload => {
        if (upload.id === id) {
          return {
            ...upload,
            state,
          };
        }
        return upload;
      }),
    );
  };

  const handleRemoveUpload = (id: string) => {
    setUploads(uploads => uploads.filter(upload => upload.id !== id));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBrowserTabClose);

    return () => window.removeEventListener('beforeunload', handleBrowserTabClose);
  }, [uploads]);

  const handleAddUpload = async (id: string, files: File[], uploadOptions: UploadOptions) => {
    const fileBatches: FileWithPath[][] = chunk(files, BATCH_SIZE);
    // TODO: Can optimise this furthur, to send all files in parallel. Blocked on BE changes to create a folder.
    try {
      for (const batch of fileBatches) {
        const uploadId = ulid();
        const fileData = await uploadOptions.fetchPresignedUrls(batch);
        setUploads(uploads =>
          uploads.concat({
            id: uploadId,
            fileData: fileData,
            state: UploadState.UPLOAD_STARTED,
            useUploadWindow: uploadOptions.useUploadWindow,
            additionalData: uploadOptions.additionalData,
          }),
        );
        await Promise.allSettled(
          fileData.map(({ file, uploadParams }) =>
            uploadTos3(
              {
                file,
                urlResponse: uploadParams,
              },
              uploadOptions.onFileUploadComplete,
            ),
          ),
        );
        handleRemoveUpload(uploadId);
      }
      updateUploadState(id, UploadState.UPLOAD_SUCCESS);
      uploadOptions.onSuccess();
    } catch (error) {
      updateUploadState(id, UploadState.UPLOAD_ERRORED);
      uploadOptions.onError('');
    }
  };

  const value = {
    uploads,
    addUpload: handleAddUpload,
    abortUpload: noop,
    removeUpload: handleRemoveUpload,
    updateUploadState: updateUploadState,
  };

  const windowUploads = uploads.filter(upload => upload.useUploadWindow);
  return (
    <>
      <DataUploadContext.Provider value={value}>{children}</DataUploadContext.Provider>
      {windowUploads.length > 0 ? (
        <Affix position={{ bottom: rem(20), right: rem(90) }}>
          <Card p={0} radius="sm" withBorder shadow="sm">
            <Accordion defaultValue="uploads" w={240}>
              <Accordion.Item value="uploads">
                <Accordion.Control
                  bg="blue"
                  pt="md"
                  sx={theme => ({ borderRadius: theme.shadows.xs })}
                >
                  <Horizontal>
                    <Text variant="subTitle05">Uploads</Text>
                  </Horizontal>
                </Accordion.Control>
                <Accordion.Panel>
                  {windowUploads.map(upload => (
                    <Box key={upload.id}>
                      {upload.fileData.map(file => (
                        <Flex key={`${upload.id}-${file.file.name}`} gap="sm">
                          {upload.state === UploadState.UPLOAD_STARTED ? (
                            <Loader size="xs" />
                          ) : null}
                          {upload.state === UploadState.UPLOAD_ERRORED ? (
                            <IconX color="red" size={24} />
                          ) : null}
                          {upload.state === UploadState.UPLOAD_SUCCESS ? (
                            <IconCheck color="green" size={24} />
                          ) : null}
                          <Text key={file.file.name} variant="bodyShort02">
                            {file.file.name}
                          </Text>
                        </Flex>
                      ))}
                    </Box>
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Card>
        </Affix>
      ) : null}
    </>
  );
};

interface StyleConfig {
  cssProperty: string;
  parseHTML: (element: HTMLElement) => boolean | string | null;
  toCSS: (() => string) | ((value: string) => string);
  commands: {
    set: string;
    unset: string;
    toggle?: string; // Toggle is optional
  };
  inputRule?: RegExp[];
  pasteRule?: RegExp[];
  keyboardShortcuts?: string[];
}

interface StyleConfigurations {
  [key: string]: StyleConfig;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textStyle: {
      setTextStyleClass: (className?: string) => ReturnType;
      toggleBold: () => ReturnType;
      setBold: () => ReturnType;
      unsetBold: () => ReturnType;
      toggleItalic: () => ReturnType;
      setItalic: () => ReturnType;
      unsetItalic: () => ReturnType;
      toggleUnderline: () => ReturnType;
      setUnderline: () => ReturnType;
      unsetUnderline: () => ReturnType;
    };
  }
}

/**
 * StyleConfigurations
 *
 * This object defines how different styles (bold, italic, underline, font family, etc.) are configured and applied within the editor.
 * Each key in `styleConfigurations` represents a different text style, and the object defines the behavior of each style.
 *
 * Keys:
 * - `bold`, `italic`, `underline`, `fontFamily`, `fontSize`, `color`: These are different styles supported by the editor.
 *
 * Properties of Each Style Configuration:
 *
 * - `cssProperty`: (string) The CSS property used to represent the style (e.g., 'font-weight' for bold, 'font-style' for italic).
 * - `parseHTML`: (function) Parses the style from an HTML element and returns the attribute value if applicable. Used to determine if the style is present on a given element when parsing existing content.
 * - `toCSS`: (function) Converts the attribute value to a CSS style value. This defines how the attribute value is reflected in CSS (e.g., 'bold' for `font-weight`).
 * - `commands`: (object) Defines the commands for the style. These include:
 *   - `toggle`: Command name to toggle the style (e.g., 'toggleBold').
 *   - `set`: Command name to set the style explicitly.
 *   - `unset`: Command name to remove the style explicitly.
 * - `inputRule`: (regex, optional) Defines a regex pattern to apply the style when the user types matching characters (e.g., `**bold**` for bold).
 * - `pasteRule`: (regex, optional) Defines a regex pattern to apply the style when matching content is pasted into the editor.
 * - `keyboardShortcuts`: (array of strings, optional) Defines the keyboard shortcuts to apply the style (e.g., 'Mod-b' or 'Mod-B' for bold).
 *
 * Example:
 * - `bold`:
 *   - `cssProperty`: 'font-weight'
 *   - `parseHTML`: Parses the `font-weight` property from an element to determine if the text should be bold.
 *   - `toCSS`: Returns the CSS value 'bold' for applying the bold style.
 *   - `commands`: Defines commands for toggling, setting, and unsetting the bold style.
 *   - `keyboardShortcuts`: Includes shortcuts like 'Mod-b' or 'Mod-B' to quickly apply or remove the bold style.
 */
export const styleConfigurations: StyleConfigurations = {
  bold: {
    cssProperty: 'font-weight',
    parseHTML: (element: HTMLElement) => {
      const fontWeight = element.style.fontWeight;
      return fontWeight === 'bold' || parseInt(fontWeight) >= 600 ? true : null;
    },
    toCSS: value => (value ? 'bold' : 'unset'),
    commands: {
      toggle: 'toggleBold',
      set: 'setBold',
      unset: 'unsetBold',
    },
    inputRule: [
      // Matches bold text via `*bold*` as input.
      /(?:^|\s)(\*\*(?!\s+\*\*)((?:[^*]+))\*\*(?!\s+\*\*))$/,
      // Matches bold text via `_bold_` as input.
      /(?:^|\s)(\_\_(?!\s+\_\_)((?:[^_]+))\_\_(?!\s+\_\_))$/,
    ],
    pasteRule: [
      // Matches bold text via `*bold*` as paste.
      /(?:^|\s)(\*\*(?!\s+\*\*)((?:[^*]+))\*\*(?!\s+\*\*))/g,
      // Matches bold text via `_bold_` as paste.
      /(?:^|\s)(\_\_(?!\s+\_\_)((?:[^_]+))\_\_(?!\s+\_\_))/g,
    ],
    keyboardShortcuts: ['Mod-b', 'Mod-B'],
  },
  italic: {
    cssProperty: 'font-style',
    parseHTML: (element: HTMLElement) => (element.style.fontStyle === 'italic' ? true : null),
    toCSS: value => (value ? 'italic' : 'unset'),
    commands: {
      toggle: 'toggleItalic',
      set: 'setItalic',
      unset: 'unsetItalic',
    },
    inputRule: [
      // Matches an italic to a *italic* on input.
      /(?:^|\s)(\*(?!\s+\*)((?:[^*]+))\*(?!\s+\*))$/,
      // Matches an italic to a _italic_ on input.
      /(?:^|\s)(\_(?!\s+\_)((?:[^_]+))\_(?!\s+\_))$/,
    ],
    pasteRule: [
      // Matches an italic to a *italic* on paste.
      /(?:^|\s)(\*(?!\s+\*)((?:[^*]+))\*(?!\s+\*))/g,
      // Matches an italic to a _italic_ on paste.
      /(?:^|\s)(\_(?!\s+\_)((?:[^_]+))\_(?!\s+\_))/g,
    ],
    keyboardShortcuts: ['Mod-i', 'Mod-I'],
  },
  underline: {
    cssProperty: 'text-decoration',
    parseHTML: (element: HTMLElement) =>
      element.style.textDecoration.includes('underline') ? true : null,
    toCSS: value => (value ? 'underline' : 'unset'),
    commands: {
      toggle: 'toggleUnderline',
      set: 'setUnderline',
      unset: 'unsetUnderline',
    },
    keyboardShortcuts: ['Mod-u', 'Mod-U'],
  },
  fontFamily: {
    cssProperty: 'font-family',
    parseHTML: (element: HTMLElement) => element.style.fontFamily || null,
    toCSS: (value: string) => value,
    commands: {
      set: 'setFontFamily',
      unset: 'unsetFontFamily',
    },
  },
  fontSize: {
    cssProperty: 'font-size',
    parseHTML: (element: HTMLElement) => element.style.fontSize || null,
    toCSS: (value: string) => value,
    commands: {
      set: 'setFontSize',
      unset: 'unsetFontSize',
    },
  },
  color: {
    cssProperty: 'color',
    parseHTML: (element: HTMLElement) => element.style.color || null,
    toCSS: (value: string) => value,
    commands: {
      set: 'setColor',
      unset: 'unsetColor',
    },
  },
};

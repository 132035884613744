import { IconExternalLink } from '@tabler/icons-react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import {
  Box,
  Card,
  LinkButton,
  Text,
  Tooltip,
  Vertical,
  useElementSize,
  useMarkovTheme,
} from '../../../..';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import { EmbeddingsDataContainer } from '../../../../../../components/dataset-details/clusters/EmbeddingsData.container';
import { useSnippetDetail } from '../../../../../../components/snippets/detail/context/SnippetContext';
import { DatasetClusterProvider } from '../../../../../../contexts/datasets/DatasetClusterContext';
import { ClusterPanelProvider } from '../../../../../../contexts/datasets/embeddings/ClusterPanelContext';
import { ViewStateProvider } from '../../../../../../contexts/datasets/embeddings/ViewStateContext';
import { useAbsoluteRoutes } from '../../../../../../router/hooks';
import { EmbeddingIllustration } from '../../../../../Icons';
import { useGridItemStyles } from '../chart-grid/ChartGrid.styles';
import { ItemActions } from '../chart-grid/ItemActions';
import { useAddComment } from '../util';

const EmbeddingChartNode = ({
  editor,
  node,
  getPos,
  deleteNode,
  updateAttributes,
}: NodeViewProps) => {
  const { editing, activeConversation, isPublicSnippet } = useSnippetDetail();

  const { cx, classes } = useGridItemStyles();
  const theme = useMarkovTheme();
  const { getDatasetDetailsRoute } = useAbsoluteRoutes();

  const { handleSetActiveConversation } = useAddComment();

  const { ref, width, height } = useElementSize();

  const embeddingId = node.attrs['data-embedding-id'];
  const datasetId = node.attrs['data-dataset-id'];
  const conversationId = node.attrs['data-conversation-id'];
  const subsetId = node.attrs['data-embedding-subset-id'];

  const isActiveConversation =
    conversationId && conversationId === activeConversation.conversationId;

  const handleDeleteViz = () => {
    deleteNode();
  };

  const handleCommentClick = () => {
    editor.commands.focus(getPos());

    const newConversationId = handleSetActiveConversation(conversationId);
    if (newConversationId) {
      updateAttributes({
        'data-conversation-id': newConversationId,
      });
    }
  };

  if (isPublicSnippet) {
    return (
      <NodeViewWrapper>
        <Card m="md" h="250px" w="50%" bg="white" shadow="md">
          <Vertical align="center" p="md">
            <Text variant="subTitle02">Embeddings</Text>
            <EmbeddingIllustration height={100} />
            <Text variant="bodyShort02" color="gray.6">
              We cannot provide support for this chart within the public snippet
            </Text>
          </Vertical>
        </Card>
      </NodeViewWrapper>
    );
  }

  // TODO: Refactor this card thing to be plug-n-play
  return (
    <NodeViewWrapper>
      <Box p="md">
        <Card
          ref={ref}
          h="600px"
          w="100%"
          pos="relative"
          bg="white"
          className={cx(classes.item, {
            [classes.itemActive]: isActiveConversation,
          })}
          sx={{ overflow: 'inherit' }}
        >
          {height > 0 && width > 0 && (
            <EmbeddingsDataContainer subsetId={subsetId} height={height} width={width} />
          )}
          <ItemActions
            onCommentClick={() => handleCommentClick()}
            onDelete={handleDeleteViz}
            item={{
              layout: {
                i: embeddingId,
                conversationId,
              },
            }}
            readOnlyMode={!editing}
          />
          <Box pos="absolute" left={24} bottom={68}>
            <LinkButton unstyled to={getDatasetDetailsRoute(datasetId, 'embeddings')}>
              <Tooltip label="View in detail">
                <IconExternalLink color={theme.colors.gray[6]} />
              </Tooltip>
            </LinkButton>
          </Box>
        </Card>
      </Box>
    </NodeViewWrapper>
  );
};

export const EmbeddingChart = (props: NodeViewProps) => (
  <ErrorBoundary>
    <DatasetClusterProvider datasetId={props.node.attrs['data-dataset-id']}>
      <ClusterPanelProvider>
        <ViewStateProvider>
          <EmbeddingChartNode {...props} />
        </ViewStateProvider>
      </ClusterPanelProvider>
    </DatasetClusterProvider>
  </ErrorBoundary>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Prism as PrismJS } from 'prism-react-renderer';

export { Prism } from '@mantine/prism';

((typeof global !== 'undefined' ? global : window) as any).Prism = PrismJS;

import('prismjs/components/prism-log');
import('prismjs/components/prism-json');

import { Box, Divider, Vertical, useMarkovTheme } from '../../../../design-system/v2';
import { GetWorkspaceCurrentSubscriptionDetailResponseModel } from '../../../../generated/api';
import { CreditsSection } from './CreditsSection';
import { PlanSectionContainer } from './PlanSection.container';

interface SubscriptionDetailsProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
  canManageSubscription: boolean;
}

export const SubscriptionDetails = ({
  subscriptionDetail,
  canManageSubscription,
}: SubscriptionDetailsProps) => {
  const theme = useMarkovTheme();
  const canUserManageSubscription = Boolean(canManageSubscription);

  return (
    <Box>
      <Vertical
        p="lg"
        sx={{ border: `1.5px solid ${theme.colors.gray[2]}`, borderRadius: theme.radius.sm }}
        spacing="lg"
      >
        <PlanSectionContainer
          subscriptionDetail={subscriptionDetail}
          canManageSubscription={canUserManageSubscription}
        />
        <Divider sx={{ border: `1px solid ${theme.colors.gray[2]}` }} />
        <CreditsSection subscriptionDetail={subscriptionDetail} />
      </Vertical>
    </Box>
  );
};

import noop from 'lodash/noop';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { DataSetModel, SegmentType } from '../../../generated/api';

export enum Mode {
  RE_ORDER = 'RE_ORDER',
  VIEW = 'VIEW',
}

interface ClassSelected {
  label: string;
  checked: boolean;
}
export interface AnalysisFilter {
  segment: SegmentType;
  checked: boolean;
  classNames: ClassSelected[];
}

interface DatasetFilter {
  [datasetId: string]: AnalysisFilter[];
}

interface AnalysisComparison {
  primaryDataset: DataSetModel;
  secondaryDatasets: DataSetModel[];

  selectedAnalyser: string;
  setSelectedAnalyser: (val: string) => void;

  datasetFilters: DatasetFilter;
  setDatasetFilters: (val: DatasetFilter) => void;

  mode: Mode;
  setMode: (mode: Mode) => void;
}

const defaultValue = {
  primaryDataset: {} as DataSetModel,
  secondaryDatasets: [],

  selectedAnalyser: '',
  setSelectedAnalyser: noop,

  datasetFilters: {},
  setDatasetFilters: noop,

  mode: Mode.VIEW,
  setMode: noop,
};

const AnalysisComparisonContext = createContext<AnalysisComparison>(defaultValue);

export const useAnalysisComparison = () => useContext(AnalysisComparisonContext);

interface AnalysisComparisonProvideProps {
  primary: DataSetModel;
  secondary: DataSetModel[];
}

export const AnalysisComparisonProvider = ({
  children,
  primary,
  secondary,
}: PropsWithChildren<AnalysisComparisonProvideProps>) => {
  const [mode, setMode] = useState<Mode>(defaultValue.mode);
  const [selectedAnalyser, setSelectedAnalyser] = useState(defaultValue.selectedAnalyser);
  const [datasetFilters, setDatasetFilters] = useState<DatasetFilter>(
    [primary, ...secondary].reduce((allFilters, dataset) => {
      allFilters[dataset.datasetId] =
        dataset.segments?.map(segment => ({
          segment: segment.segmentType,
          checked: true,
          classNames:
            segment?.classNames?.map(cl => ({
              label: cl,
              checked: true,
            })) ?? [],
        })) ?? [];
      return allFilters;
    }, {} as DatasetFilter),
  );

  return (
    <AnalysisComparisonContext.Provider
      value={{
        primaryDataset: primary,
        secondaryDatasets: secondary,

        selectedAnalyser,
        setSelectedAnalyser: setSelectedAnalyser,

        datasetFilters,
        setDatasetFilters,

        mode,
        setMode,
      }}
    >
      {children}
    </AnalysisComparisonContext.Provider>
  );
};

import { MantineSize, createStyles } from '@mantine/styles';

interface TagStylesParams {
  size: MantineSize;
  selected: boolean;
  clickable: boolean;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  maw?: number;
}

export const getButtonSize = (size: MantineSize) => {
  switch (size) {
    case 'xs':
    case 'sm':
      return 12;
    case 'md':
    case 'lg':
    case 'xl':
    default:
      return 16;
  }
};

const getTagHeight = (size: MantineSize) => {
  switch (size) {
    case 'xs':
      return 20;
    case 'sm':
      return 22;
    case 'lg':
      return 28;
    case 'xl':
      return 32;
    case 'md':
    default:
      return 24;
  }
};

export const useTagStyles = createStyles(
  (
    theme,
    { size, color, backgroundColor, borderColor, selected, clickable, maw = 128 }: TagStylesParams,
  ) => ({
    root: {
      color: color ?? (selected ? theme.colors.white[0] : theme.colors.dark[4]),
      backgroundColor: backgroundColor ?? (selected ? theme.colors.blue[8] : theme.colors.white[0]),
      borderColor: borderColor ?? (selected ? theme.colors.blue[8] : '#D8E0EC'),
      textTransform: 'none',
      height: getTagHeight(size),
      padding: '0 12px',
      fontWeight: 500,
      cursor: clickable ? 'pointer' : 'auto',
    },
    inner: {
      maxWidth: maw,
      textOverflow: 'ellipse',
    },
  }),
);

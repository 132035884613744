import { ResourceRequest, StorageType } from '../../../../generated/api';

export interface ResourceActionsProps {
  connectorId: string;
  connectorType: StorageType;
  resourcePath: ResourceRequest[];
  isAdded: boolean;
  resourceId?: string;
  onResourceAdd?: (resourcePath: string) => void;
  isPreviewDisabled?: boolean;
}
export const formatDateToAPISpec = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
};

export const initializeStartDate = (): Date => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 1);
  currentDate.setHours(0, 0, 0, 0);
  return currentDate;
};

import { ContainerProps, Container as MantineContainer } from '@mantine/core';
import { forwardRef } from 'react';

export type { ContainerProps } from '@mantine/core';

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, ...rest }, ref) => (
    <MantineContainer size="xl" px={32} {...rest} ref={ref}>
      {children}
    </MantineContainer>
  ),
);

Container.displayName = 'Container';

import dropRight from 'lodash/dropRight';
import first from 'lodash/first';
import last from 'lodash/last';
import { FileRejection } from 'react-dropzone';
import {
  Button,
  Center,
  Dropzone,
  DropzoneProps,
  FileWithPath,
  Text,
  Vertical,
} from '../../../../design-system/v2';
import {
  IMAGE_MIME_TYPE,
  PDF_MIME_TYPE,
  TEXT_MIME_TYPE,
} from '../../../../design-system/v2/dropzone/mime-types';

const getFolderNameFromFiles = (files: FileWithPath[]): string => {
  const firstFile = first(files);

  if (!firstFile || !firstFile.path) {
    return '';
  }

  const pathParts = firstFile.path.split('/');
  if (pathParts.length === 1) {
    return first(pathParts) || '';
  } else {
    return last(dropRight(pathParts)) || '';
  }
};

const filesToAccept = [...TEXT_MIME_TYPE, ...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE];

export interface Folder {
  name: string;
  files?: FileWithPath[];
}

interface FolderUploadInputProps extends Omit<DropzoneProps, 'children' | 'onDrop'> {
  folder?: Folder;
  onFolderDrop?: (
    folderName: string,
    files: FileWithPath[],
    fileRejections?: FileRejection[],
  ) => void;
}

export const FolderUploadInput = ({ folder, onFolderDrop, ...rest }: FolderUploadInputProps) => {
  const handleFolderDrop = (files: FileWithPath[], fileRejections?: FileRejection[]) => {
    onFolderDrop?.(getFolderNameFromFiles(files), files, fileRejections);
  };

  return (
    <Dropzone
      onDropAny={handleFolderDrop}
      accept={filesToAccept}
      py={32}
      useFsAccessApi={false}
      useWebkitDirectory
    >
      <Center>
        <Vertical align="center" spacing="sm">
          <Vertical spacing={0} align="center">
            <Text variant="subTitle04" color="gray.7">
              Drag and drop to upload your folder
            </Text>
            <Text variant="subTitle04" color="gray.7">
              or
            </Text>
          </Vertical>
          <Button variant="outline">Choose a folder</Button>
        </Vertical>
      </Center>
    </Dropzone>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { AccountOwnerType, PaymentSubscriptionType } from '../../generated/api';
import { paymentRoutesApi } from '../../lib/api/haifa';
import { useGetWorkspaceSettingsDetailsQuery } from './workspace';

export const paymentAndSubscriptionKeys = {
  all: ['payment-and-subscription'] as const,
  getCurrentSubscription: (workspaceId: string) =>
    [...paymentAndSubscriptionKeys.all, 'current-subscription', workspaceId] as const,
};

export const useGetWorkspaceCurrentSubscriptionDetailsQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    paymentAndSubscriptionKeys.getCurrentSubscription(workspaceId),
    () => paymentRoutesApi.getWorkspaceCurrentSubscriptionDetailV1(workspaceId),
    {
      enabled: !!workspaceId,
      select: data => data.data,
    },
  );
};

export const useCreateCheckoutSessionMutation = () => {
  const { workspaceId } = useAppMetadata();

  return useMutation({
    mutationFn: (subscriptionType: PaymentSubscriptionType) =>
      paymentRoutesApi.createWorkspaceCheckoutSessionV1(workspaceId, subscriptionType),
  });
};

export const useSetCurrentWorkspaceSubscriptionFreemiumMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => paymentRoutesApi.createWorkspaceFreemiumSubscriptionV1(workspaceId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: paymentAndSubscriptionKeys.getCurrentSubscription(workspaceId),
      });
    },
  });
};

/**
 * Determines if a workspace is eligible for a trial based on account type.
 * Enterprise workspaces are not eligible for Teams plan trial messaging.
 */
const isEligibleForTrial = (accountType: AccountOwnerType): boolean =>
  accountType !== AccountOwnerType.Enterprise;

interface WorkspaceTrialEligibility {
  isLoading: boolean;
  isError: boolean;
  isEligible: boolean;
}

export const useGetWorkspaceTrialEligibilityQuery = (): WorkspaceTrialEligibility => {
  const { isLoading, isError, data: workspaceSettings } = useGetWorkspaceSettingsDetailsQuery();

  const isEligible =
    !isLoading && !isError && isEligibleForTrial(workspaceSettings.account.accountType);

  return {
    isLoading,
    isError,
    isEligible,
  };
};

export const useCreateBillingPortalSessionMutation = (returnUrl: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation({
    mutationFn: () =>
      paymentRoutesApi.createWorkspaceBillingPortalSessionV1(workspaceId, {
        returnUrl,
      }),
  });
};

export function mergeAndStringifyStyles(
  existingStyleString: string | null,
  newStyles: Record<string, string> = {},
) {
  // Parse existing styles into an object
  const existingStyles = existingStyleString
    ? (existingStyleString.split(';') as string[]).reduce((acc, style) => {
        const [key, value] = style.split(':').map(s => s.trim());
        if (key && value) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, string>)
    : {};

  // Merge existing styles with new styles
  const mergedStyles = { ...existingStyles, ...newStyles };

  // Convert the merged styles back into a style string
  return Object.entries(mergedStyles)
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ');
}

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
// Core grid CSS, always needed
import '@ag-grid-community/styles/ag-grid.css';
// Optional theme CSS
import '@ag-grid-community/styles/ag-theme-alpine.css';

export type {
  CellClickedEvent,
  ColDef,
  GetRowIdFunc,
  ICellRendererParams,
  IDatasource,
  IGetRowsParams,
  PaginationChangedEvent,
  RowClickedEvent,
  RowSelectedEvent,
} from '@ag-grid-community/core';

export { AgGridReact } from '@ag-grid-community/react';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

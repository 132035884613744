import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { findNodesWithNoIncomingEdges, topologicalSort } from '../../components/workflows/util';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  CreateScheduledWorkflowRequest,
  CreateWorkflowDebugRunRequest,
  GetDAGResponse,
  GetWorkflowRunStatusDetailsResponse,
  UpdateScheduledWorkflowRequest,
  WorkflowRunDagNodeStatus,
  WorkflowRunOperatorStatus,
  WorkflowRunStatus,
  WorkflowRunStatusDetailsResponse,
  WorkflowStatus,
} from '../../generated/api';
import { workflowApi } from '../../lib/api';
import { workflowsQueryKeys } from './list/list';
import { operatorKeys } from './operators';

export const debugRunKeys = {
  all: ['workflow-debug-'],
  initDebug: (workspaceId: string, workflowId: string) => [
    ...debugRunKeys.all,
    workspaceId,
    workflowId,
  ],
  debugRunStatus: (workspaceId: string, workflowId: string, runId?: string) => [
    ...debugRunKeys.all,
    workspaceId,
    workflowId,
    runId,
  ],
};

export const useInitDebugMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: CreateWorkflowDebugRunRequest) =>
      workflowApi.createWorkflowDebugRunV1(workspaceId, workflowId, req),
    {
      onMutate: async newDebugRun => {
        const key = debugRunKeys.debugRunStatus(
          workspaceId,
          workflowId,
          `${workspaceId}-${workflowId}-debug-run`,
        );

        await queryClient.cancelQueries(key);

        const previousData =
          queryClient.getQueryData<AxiosResponse<GetWorkflowRunStatusDetailsResponse>>(key);

        const dag = queryClient.getQueryData<AxiosResponse<GetDAGResponse>>(
          workflowsQueryKeys.dag(workspaceId, workflowId),
        );

        if (previousData && dag && dag.data.nodes) {
          const nodes = dag.data.nodes;
          const edges = dag.data.edges;
          const topoSort = topologicalSort(edges, nodes);
          const nodesWithNoIncomingEdges = findNodesWithNoIncomingEdges(edges);

          const operatorsStatus = topoSort.map(
            (nodeId): WorkflowRunDagNodeStatus => ({
              nodeId: nodeId,
              statusDetails: {
                operatorId: nodes.find(node => node.id === nodeId)?.data.id ?? '',
                status: nodesWithNoIncomingEdges.includes(nodeId)
                  ? WorkflowRunOperatorStatus.Running
                  : WorkflowRunOperatorStatus.NotStarted,
              },
            }),
          );

          const updatedStatus = {
            ...previousData.data.response,
            dag: dag?.data,
            dagHash: dag?.data.dagHash,
            wflRunStatus: WorkflowRunStatus.Running,
            operatorsStatus,
            ...newDebugRun,
          };

          queryClient.setQueryData(key, {
            ...previousData,
            data: {
              response: updatedStatus,
            },
          });
        }

        return { previousData };
      },

      onSuccess: () => {
        queryClient.invalidateQueries(workflowsQueryKeys.listForRuns(workspaceId, workflowId));
        queryClient.invalidateQueries(
          workflowsQueryKeys.runsDetail(
            workspaceId,
            workflowId,
            `${workspaceId}-${workflowId}-debug-run`,
          ),
        );
        queryClient.invalidateQueries(
          operatorKeys.getWorkflowDagNodesSchema(
            workspaceId,
            workflowId,
            `${workspaceId}-${workflowId}-debug-run`,
          ),
        );
      },

      onError: (error, variables, context) => {
        if (context?.previousData) {
          queryClient.setQueryData(
            debugRunKeys.debugRunStatus(
              workspaceId,
              workflowId,
              `${workspaceId}-${workflowId}-debug-run`,
            ),
            context.previousData,
          );
        }
      },
    },
  );
};

export const usePublishWorkflowMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    ({ workflowName }: { execute?: boolean; workflowName?: string }) =>
      workflowApi.publishWorkflowV1(workspaceId, workflowId, false, workflowName),
    {
      onSuccess: () => queryClient.invalidateQueries(workflowsQueryKeys.all),
    },
  );
};

export const useDebugRunStatusQuery = (workflowId: string, runId = '') => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery<
    AxiosResponse<GetWorkflowRunStatusDetailsResponse>,
    AxiosError,
    WorkflowRunStatusDetailsResponse
  >(
    debugRunKeys.debugRunStatus(workspaceId, workflowId, runId),
    () => workflowApi.getWorkflowRunStatusV1(workspaceId, workflowId, runId),
    {
      enabled: !!workspaceId && !!workflowId && !!runId,
      refetchInterval: data =>
        data?.wflRunStatus === WorkflowRunStatus.Terminated ||
        data?.wflRunStatus === WorkflowRunStatus.Success ||
        data?.wflRunStatus === WorkflowRunStatus.ExecutionFailed
          ? false
          : 10 * 1000,
      select: data => data.data.response,
      onSuccess: data => {
        if (
          data.wflRunStatus === WorkflowRunStatus.Terminated ||
          data.wflRunStatus === WorkflowRunStatus.Success ||
          data.wflRunStatus === WorkflowRunStatus.ExecutionFailed
        ) {
          queryClient.invalidateQueries(
            workflowsQueryKeys.runsDetail(workspaceId, workflowId, runId),
          );
        }
      },
    },
  );
};

export const useDownloadWorkflowOutputMutation = (workflowId: string, workflowRunId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation(() =>
    workflowApi.downloadWorkflowOutputV1(workspaceId, workflowId, workflowRunId),
  );
};

export const usePublishScheduledWorkflowMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: CreateScheduledWorkflowRequest) =>
      workflowApi.createScheduledWorkflowV1(workspaceId, workflowId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.all,
        });
      },
    },
  );
};

export const useUpdateScheduledWorkflowMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UpdateScheduledWorkflowRequest) =>
      workflowApi.updateScheduledWorkflowV1(workspaceId, workflowId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.all,
        });
      },
    },
  );
};

export const useUpdateScheduledWorkflowStateMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (status: WorkflowStatus) =>
      workflowApi.updateScheduledWorkflowStateV1(workspaceId, workflowId, status),
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.all,
        }),
    },
  );
};

export const useDownloadWorkflowNodeOutputMutation = (
  workflowId: string,
  runId: string,
  nodeId: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useMutation(() =>
    workflowApi.downloadWorkflowNodeOutputV1(workspaceId, workflowId, runId, nodeId),
  );
};

export const useEmailTriggerPublishMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => workflowApi.publishTriggerWorkflowV1(workspaceId, workflowId), {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: workflowsQueryKeys.all,
      }),
  });
};

import {
  Box,
  Horizontal,
  Image,
  LinkAnchor,
  Progress,
  Text,
  Vertical,
  useMarkovTheme,
} from '../../../../design-system/v2';
import { MARKOVML_LEARN_ABOUT_CREDITS } from '../../../../developer-docs/links';
import { GetWorkspaceCurrentSubscriptionDetailResponseModel } from '../../../../generated/api';
import { formatDateLong } from '../../../../lib/ui';
import {
  CREDITS_COIN_ICON_URL,
  CREDITS_COIN_ICON_WIDTH,
  CREDITS_HORIZONTAL_SPACING,
} from '../../../subscriptions/util';

interface CreditsSectionProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
}

export const CreditsSection = ({ subscriptionDetail }: CreditsSectionProps) => {
  const theme = useMarkovTheme();
  const { totalCredit, usedCredit, currentPeriodEnd } = subscriptionDetail;
  const currentPeriodEndDate = new Date(currentPeriodEnd * 1000);
  const formattedCurrentPeriodEnd = formatDateLong(currentPeriodEndDate);

  const availableCredits = totalCredit - usedCredit;
  const creditUsagePercentage = totalCredit > 0 ? (availableCredits / totalCredit) * 100 : 0;

  return (
    <Box w="100%">
      <Text color="gray.6">Credits</Text>
      <Vertical pt="md">
        <Vertical>
          <Horizontal spacing={CREDITS_HORIZONTAL_SPACING}>
            <Image src={CREDITS_COIN_ICON_URL} width={CREDITS_COIN_ICON_WIDTH} />
            <Text variant="bodyShort02">
              <Text variant="subTitle04" span>
                {`${availableCredits}/${totalCredit}`}
              </Text>{' '}
              of monthly credits left
            </Text>
          </Horizontal>
          <Vertical
            pl={CREDITS_COIN_ICON_WIDTH + CREDITS_HORIZONTAL_SPACING}
            spacing={CREDITS_HORIZONTAL_SPACING}
          >
            <Progress value={creditUsagePercentage} size="sm" maw={400} color="yellow" />
            <Text variant="small02">
              Unused credits will expire on {formattedCurrentPeriodEnd}.
            </Text>
          </Vertical>
        </Vertical>
        <Horizontal position="apart">
          <Text color="gray.6">Credit usage (Details coming soon)</Text>
          <LinkAnchor
            href={MARKOVML_LEARN_ABOUT_CREDITS}
            target="_blank"
            sx={{ color: 'blue' }}
            external
          >
            <Text variant="textLink" sx={{ fontSize: theme.fontSizes.sm }} color="blue.6">
              Learn about credits
            </Text>
          </LinkAnchor>
        </Horizontal>
      </Vertical>
    </Box>
  );
};

import css from '@styled-system/css';
import styled from 'styled-components';
import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  border,
  color,
  compose,
  flexbox,
  layout,
  space,
  variant,
} from 'styled-system';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'danger';

export interface ButtonVariantProp {
  variant?: ButtonVariant;
}

type ButtonProps = ButtonVariantProp &
  SpaceProps &
  ColorProps &
  FlexboxProps &
  LayoutProps &
  BorderProps;

const styleProps = compose(flexbox, color, space, layout, border);

const Button = styled('button')<ButtonProps>(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '56px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
    padding: '16px 24px',
    cursor: 'pointer',
    ':disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  }),
  variant({
    variants: {
      primary: {
        color: '#FFFFFF',
        bg: 'primary.base',
        border: '1px solid #0C6EFC',
      },
      secondary: {
        color: '#3C505C',
        bg: '#fff',
        border: 'thickSolid',
        borderColor: 'muted.shade1',
      },
      tertiary: {
        color: '#3C505C',
        bg: 'transparent',
        border: 'none',
      },
      danger: {
        color: '#FFF',
        bg: 'dangerRed',
        border: 'none',
      },
    },
  }),
  styleProps,
);

export default Button;

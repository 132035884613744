import { MantineTheme } from '@mantine/styles';
import { useState } from 'react';
import { DIFF_TYPE } from '../../../../../components/common/constants';
import { CopyEditCitationText } from '../../../../../generated/api';
import { interleaveComponent } from '../../../../../lib/util-react';
import { Box, CSSObject, HTMLPreview, Popover, Text } from '../../../core';

export const ReferenceText = ({ value }: { value: string }) => (
  <span style={{ color: 'blue' }}>{value}</span>
);

const getBoxStyles = (highlightType: number, theme: MantineTheme): CSSObject => {
  const highlightBorderMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[3],
    [DIFF_TYPE.EQUAL]: theme.colors.gray[3],
    [DIFF_TYPE.DELETE]: theme.colors.red[3],
  };
  const highlightBackgroundMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[0],
    [DIFF_TYPE.EQUAL]: '',
    [DIFF_TYPE.DELETE]: theme.colors.red[0],
  };
  return {
    position: 'relative',
    borderRadius: '4px',
    display: 'inline-block',
    borderStyle: 'dashed',
    borderColor: highlightBorderMap[highlightType],
    backgroundColor: highlightBackgroundMap[highlightType],
  };
};
interface ListReferenceProps {
  referenceObj: CopyEditCitationText;
}

export const ReferenceComponent = ({ referenceObj }: ListReferenceProps): JSX.Element => {
  const refSummary = referenceObj.citationHtml;

  if (refSummary) {
    return <HTMLPreview style={{ padding: '2px 0' }}>{refSummary}</HTMLPreview>;
  }

  return <></>;
};

interface MarkovReferenceItemProps {
  citation: CopyEditCitationText;
  fullCitation: CopyEditCitationText;
  highlightType: number;
}

const MarkovReferenceItem = ({
  citation,
  fullCitation,
  highlightType,
}: MarkovReferenceItemProps): JSX.Element => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleOnClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  return (
    <Box component="span" sx={theme => getBoxStyles(highlightType, theme)}>
      <Popover
        width={200}
        position="bottom"
        withArrow
        shadow="md"
        withinPortal
        offset={4}
        styles={{
          dropdown: {
            maxWidth: '100%', // Ensure the dropdown doesn't exceed the parent width
            wordWrap: 'break-word', // Wrap words within the dropdown
            whiteSpace: 'normal', // Allow text to wrap to the next line
          },
        }}
      >
        <Popover.Target>
          <Text
            variant="bodyShort03"
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={handleOnClick}
          >
            {citation.citationText}
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <ReferenceComponent referenceObj={fullCitation} />
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};

interface InlineCitationComponentProps {
  citations: CopyEditCitationText[];
  fullCitations: CopyEditCitationText[];
  highlightType: number;
}

export const InlineCitationComponent = ({
  citations,
  fullCitations,
  highlightType,
}: InlineCitationComponentProps): JSX.Element => {
  const delimiter = '; ';

  const citationJSX = citations.map(citation => (
    <MarkovReferenceItem
      key={citation.citationId}
      citation={citation}
      highlightType={highlightType}
      fullCitation={
        fullCitations.find(fullCitation => fullCitation.citationId === citation.citationId) ??
        citation
      }
    />
  ));

  return (
    <span>
      {citations &&
        interleaveComponent(citationJSX, () => <ReferenceText value={delimiter ?? ' '} />)}
    </span>
  );
};

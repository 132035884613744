import isEmpty from 'lodash/isEmpty';
import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';
import { TAGS_COLUMN_ID } from '../../components/common/table/Columns';
import { TagMetadata } from '../../generated/api';

export type ColumnFilters = Record<string, unknown> & { [TAGS_COLUMN_ID]?: TagMetadata[] };

interface TableContextState {
  filters: ColumnFilters;
  setFilters: (filters: ColumnFilters) => void;
  updateFilters: (filters: Partial<ColumnFilters>) => void;
  clearTag: (tagId: string) => void;
}

const TableContext = createContext<TableContextState | undefined>(undefined);

export const useTableContext = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error('useTableContext must be called from within a TableContextProvider instance');
  }
  return context;
};

export const TableContextProvider = ({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element => {
  const [filters, setFilters] = useState<ColumnFilters>({});

  const value: TableContextState = useMemo(
    () => ({
      filters,
      setFilters,
      updateFilters: (partial: Partial<ColumnFilters>) => {
        setFilters({ ...filters, ...partial });
      },
      clearTag: (tagId: string) => {
        const nextFilters = { ...filters };
        const nextTags = filters[TAGS_COLUMN_ID]?.filter(t => t.tagId !== tagId);
        if (!isEmpty(nextTags)) {
          nextFilters[TAGS_COLUMN_ID] = nextTags;
        } else if (nextFilters[TAGS_COLUMN_ID]) {
          delete nextFilters[TAGS_COLUMN_ID];
        }
        setFilters(nextFilters);
      },
    }),
    [filters, setFilters],
  );

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};
